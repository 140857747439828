<template>
  <!--
  <v-app-bar app clipped-left>
    This is the Appbar (I would like to have this full-width)
  </v-app-bar>
  -->

  <v-app-bar app clipped-left extensionHeight="30px" flat class="white pl-0 ml-0 mr-0 pr-0">
    <vg-header></vg-header>

    <template v-slot:extension class="">
      <v-toolbar color="primary" height="30px" dark class="pl-0 ml-0 mr-0 pr-0">
        <v-toolbar-title></v-toolbar-title>
        <vg-app-toolbar-items :toolbarItems="toolbarItems" positioned="LEFT" />
        <v-spacer></v-spacer>
        <vg-app-toolbar-items :toolbarItems="toolbarItems" positioned="RIGHT" />
      </v-toolbar>
    </template>
  </v-app-bar>
</template>

<script>
import { gLog } from '@/services/GLogger';

// ToolbarItems
import { toolbarItems } from '@/data/toolbarItems';
// import VgHeader from './VgHeader.vue';

export default {
  name: 'VgAppToolbar',

  mixins: [], // End of mixins

  computed: {}, // End of computed

  components: {
    // VgHeader
  }, // End of components

  data: () => ({
    toolbarItems,
  }), // End of data

  methods: {}, // End of methods

  // -- Start HOOKS --
  watch: {},

  mounted() {},

  async activated() {},

  async beforeUpdate() {},

  async updated() {},
  // --  End HOOKS --
};
</script>

<style scoped></style>
