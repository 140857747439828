<template>
  <div>
    <v-tabs v-model="_value" dense>
      <v-tab v-for="(vgtab, i) in vgTabs" :key="i">{{ vgtab.caption }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="_value">
      <v-tab-item v-for="(vgtab, i) in vgTabs" :key="i">
        <component :is="vgtab.content" :dtaEdit="dtaEdit" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'VgTabs',

  props: {
    value: {
      type: null,
      required: true,
    },

    vgTabs: {
      type: Array,
      required: true,
      default: () => [],
    },

    dtaEdit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  }, // End of PROPS

  computed: {
    // Handles the value prop in a "private" way
    // The "value" prop is the incoming v-model
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  }, // end of computed

  data: () => ({}), // End of data

  methods: {}, // End of methods
}; // End of Export
</script>

<style scoped></style>
