/**
 * Generated     With : Geoit.Swagger.Parser.Generator.Node
 *            Version : 0.3.30
 *                 On : 12/3/2021, 08:54:28
 * Parsed swaggerJSON
 *              Title : VersteendeGetuigenissen.API
 *           Location : https://vg-beta.geo-it.be/api/swagger/docs/v1
 *            Version : v1
 *           API Root : https://vg-beta.geo-it.be/api
 *
 * @summary Service  file to handle Api Endpont GraveSheet
 *
 * @link                https://vg-beta.geo-it.be/api/swagger/docs/v1
 * @file                GraveSheet.js.
 * @author              Johnny Driesen <johnny.driesen@geoit.be>.
 * @version             0.3.30
 * @since               x.y.z
 * @copyright           Geo-IT bv
 */

import { HTTP, getExtraHeaders } from './base';
import { gLog } from '@/services/GLogger';

// const END_POINT = 'GraveSheet';

// ------------ GET ---------------------------------------------
/**
 * @function getGraveSheet
 */

// -- cntEndpoints: 52 .:. /GraveSheet
export const getGraveSheet = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/GraveSheet`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.get(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ GET ---------------------------------------------
/**
 * @function getGraveSheetById
 */

// -- cntEndpoints: 54 .:. /GraveSheet/{id}
export const getGraveSheetById = async (id, params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/GraveSheet/${id}`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.get(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ GET ---------------------------------------------
/**
 * @function getLocationById
 */

// -- cntEndpoints: 62 .:. /GraveSheet/{id}/Location
export const getLocationById = async (id, params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/GraveSheet/${id}/Location`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.get(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ GET ---------------------------------------------
/**
 * @function getAllGraveSheetsInProduction
 */

// -- cntEndpoints: 64 .:. /GraveSheet/AllGraveSheetsInProduction
export const getAllGraveSheetsInProduction = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/GraveSheet/AllGraveSheetsInProduction`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.get(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postGraveSheet
 */

// -- cntEndpoints: 53 .:. /GraveSheet
export const postGraveSheet = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { Location,Criteria,Concession,Epigraphy,Architecture,Sculpture,Iconography,Bibliography,BuriedPeople,Photos,Documents,Status,Vid,InventoryNumber,OldInventoryNumber,GeneralDescription,GeneralRelativeDating,ImageFilename,Name,BuriedPeopleRemark,BuriedPeopleSource,LastEditedBy,LastEditedDate,LastApprovedBy,LastApprovedDate } = params ;  
		*/

    const endpoint = `/GraveSheet`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postClearGraveSheetArchitecturePersonalityById
 */

// -- cntEndpoints: 56 .:. /GraveSheet/{id}/ClearGraveSheetArchitecturePersonality
export const postClearGraveSheetArchitecturePersonalityById = async (id, params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/GraveSheet/${id}/ClearGraveSheetArchitecturePersonality`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postClearGraveSheetPhotographerById
 */

// -- cntEndpoints: 57 .:. /GraveSheet/{id}/ClearGraveSheetPhotographer
export const postClearGraveSheetPhotographerById = async (id, params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/GraveSheet/${id}/ClearGraveSheetPhotographer`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postClearGraveSheetSculptureEmbeddedPersonalityById
 */

// -- cntEndpoints: 58 .:. /GraveSheet/{id}/ClearGraveSheetSculptureEmbeddedPersonality
export const postClearGraveSheetSculptureEmbeddedPersonalityById = async (id, params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/GraveSheet/${id}/ClearGraveSheetSculptureEmbeddedPersonality`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postClearGraveSheetSculptureFreePersonalityById
 */

// -- cntEndpoints: 59 .:. /GraveSheet/{id}/ClearGraveSheetSculptureFreePersonality
export const postClearGraveSheetSculptureFreePersonalityById = async (id, params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/GraveSheet/${id}/ClearGraveSheetSculptureFreePersonality`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postIsInEditById
 */

// -- cntEndpoints: 60 .:. /GraveSheet/{id}/IsInEdit
export const postIsInEditById = async (id, params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/GraveSheet/${id}/IsInEdit`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postIsInValidationById
 */

// -- cntEndpoints: 61 .:. /GraveSheet/{id}/IsInValidation
export const postIsInValidationById = async (id, params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/GraveSheet/${id}/IsInValidation`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postGravePoint
 */

// -- cntEndpoints: 65 .:. /GraveSheet/GravePoint
export const postGravePoint = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { Block,Row,Nr,Niscode,Code } = params ;  
		*/

    const endpoint = `/GraveSheet/GravePoint`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postPublish
 */

// -- cntEndpoints: 67 .:. /GraveSheet/Publish
export const postPublish = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { Location,Criteria,Concession,Epigraphy,Architecture,Sculpture,Iconography,Bibliography,BuriedPeople,Photos,Documents,Status,Vid,InventoryNumber,OldInventoryNumber,GeneralDescription,GeneralRelativeDating,ImageFilename,Name,BuriedPeopleRemark,BuriedPeopleSource,LastEditedBy,LastEditedDate,LastApprovedBy,LastApprovedDate } = params ;  
		*/

    const endpoint = `/GraveSheet/Publish`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postUpdateGraveSheetEditable
 */

// -- cntEndpoints: 68 .:. /GraveSheet/UpdateGraveSheetEditable
export const postUpdateGraveSheetEditable = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { Location,Criteria,Concession,Epigraphy,Architecture,Sculpture,Iconography,Bibliography,BuriedPeople,Photos,Documents,Status,Vid,InventoryNumber,OldInventoryNumber,GeneralDescription,GeneralRelativeDating,ImageFilename,Name,BuriedPeopleRemark,BuriedPeopleSource,LastEditedBy,LastEditedDate,LastApprovedBy,LastApprovedDate } = params ;  
		*/

    const endpoint = `/GraveSheet/UpdateGraveSheetEditable`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postUpdateGraveSheetReadyForValidation
 */

// -- cntEndpoints: 69 .:. /GraveSheet/UpdateGraveSheetReadyForValidation
export const postUpdateGraveSheetReadyForValidation = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { Location,Criteria,Concession,Epigraphy,Architecture,Sculpture,Iconography,Bibliography,BuriedPeople,Photos,Documents,Status,Vid,InventoryNumber,OldInventoryNumber,GeneralDescription,GeneralRelativeDating,ImageFilename,Name,BuriedPeopleRemark,BuriedPeopleSource,LastEditedBy,LastEditedDate,LastApprovedBy,LastApprovedDate } = params ;  
		*/

    const endpoint = `/GraveSheet/UpdateGraveSheetReadyForValidation`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postList
 */

// -- cntEndpoints: 70 .:. /GraveSheet/list
export const postList = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { Administration,HasAdministration,Municipalities,Municipalty,HasMunicipalty,Cemetery,Block,Row,Number,HasLocation,Name,Firstname,HasPerson,FreeSearchTerm,HasFreeSearchTerm,User,HasUser,OwnList,HasOwnList,Status,HasStatus,RelativeDating,HasRelativeDating,Scales,HasScales,Conservations,HasConservations,Animals,HasAnimals,ArchitectureDesigners,HasArchitectureDesigners,ArchitectureExecutants,HasArchitectureExecutants,ArchitectureMaterials,HasArchitectureMaterials,ArchitectureStyles,HasArchitectureStyles,ArchitectureTypes,HasArchitectureTypes,ChapleFinishings,HasChapleFinishings,Photographers,HasPhotographers,Graphical,HasGraphical,Heraldry,HasHeraldry,Interpretations,HasInterpretations,Languages,HasLanguages,LetterSymbols,HasLetterSymbols,Objects,HasObjects,ProfessionTied,HasProfessionTied,Scripts,HasScripts,SculptureEmbeddedDescriptions,HasSculptureEmbeddedDescriptions,SculptureEmbeddedDesigners,HasSculptureEmbeddedDesigners,SculptureEmbeddedExecutants,HasSculptureEmbeddedExecutants,SculptureEmbeddedMaterials,HasSculptureEmbeddedMaterials,SculptureFreeDescriptions,HasSculptureFreeDescriptions,SculptureFreeDesigners,HasSculptureFreeDesigners,SculptureFreeExecutants,HasSculptureFreeExecutants,SculptureFreeMaterials,HasSculptureFreeMaterials,SculptureSmallDescriptions,HasSculptureSmallDescriptions,SculptureSmallMaterials,HasSculptureSmallMaterials,Vegetation,HasVegetation,HasFilterDictionaries,HasIconographySymbolsOrnaments,EvaluationValues,HasEvaluationValues,EvaluationContext,HasEvaluationContext,EvaluationCategories,HasEvaluationCategories,EvaluationCategoriesExtra,HasEvaluationCategoriesExtra,HasEvaluationCriteria,IsValidFilter,IsEmptyFilter,Pagination } = params ;  
		*/

    const endpoint = `/GraveSheet/list`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ PUT ---------------------------------------------
/**
 * @function putGraveSheetSetEditable
 */

// -- cntEndpoints: 66 .:. /GraveSheet/GraveSheetSetEditable
export const putGraveSheetSetEditable = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { Location,Criteria,Concession,Epigraphy,Architecture,Sculpture,Iconography,Bibliography,BuriedPeople,Photos,Documents,Status,Vid,InventoryNumber,OldInventoryNumber,GeneralDescription,GeneralRelativeDating,ImageFilename,Name,BuriedPeopleRemark,BuriedPeopleSource,LastEditedBy,LastEditedDate,LastApprovedBy,LastApprovedDate } = params ;  
		*/

    const endpoint = `/GraveSheet/GraveSheetSetEditable`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.put(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ DELETE ---------------------------------------------
/**
 * @function deleteGraveSheetById
 */

// -- cntEndpoints: 55 .:. /GraveSheet/{id}
export const deleteGraveSheetById = async (id, params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/GraveSheet/${id}`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.delete(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ DELETE ---------------------------------------------
/**
 * @function deletePublishedById
 */

// -- cntEndpoints: 63 .:. /GraveSheet/{id}/published
export const deletePublishedById = async (id, params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/GraveSheet/${id}/published`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.delete(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};
