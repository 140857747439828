/**
 * Generated     With : Geoit.Swagger.Parser.Generator.Node 
 *            Version : 0.3.30
 *                 On : 12/3/2021, 08:54:28
 * Parsed swaggerJSON 
 *              Title : VersteendeGetuigenissen.API
 *           Location : https://vg-beta.geo-it.be/api/swagger/docs/v1
 *            Version : v1
 *           API Root : https://vg-beta.geo-it.be/api
 *
 * @summary Service  file to handle Api Endpont SculptureEmbeddedDescriptions
 *
 * @link                https://vg-beta.geo-it.be/api/swagger/docs/v1
 * @file                SculptureEmbeddedDescriptions.js.
 * @author              Johnny Driesen <johnny.driesen@geoit.be>.
 * @version             0.3.30
 * @since               x.y.z
 * @copyright           Geo-IT bv
 */
 

import { HTTP, getExtraHeaders } from './base';
import { gLog } from '@/services/GLogger';

// const END_POINT = 'SculptureEmbeddedDescriptions';



// ------------ GET ---------------------------------------------
/** 
 * @function getSculptureEmbeddedDescriptions 
 */


// -- cntEndpoints: 110 .:. /SculptureEmbeddedDescriptions
export const getSculptureEmbeddedDescriptions = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/SculptureEmbeddedDescriptions`;

		const fixedParams = {};
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.get(endpoint, { headers, params });
		
		
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};


