<template>
  <!--
    outlined
    hide-details="auto"
  -->

  <v-text-field
    v-model="_value"
    outlined
    hide-details="auto"
    class="mb-0 pb-0 mt-0 pt-0"
    dense
    v-bind="{ ...$props, ...$attrs }"
    v-on="{ ...$listeners }"
  />
</template>

<script>
export default {
  name: 'gTextField',

  props: {
    value: {
      type: null,
      required: true,
    },
  }, // End of PROPS

  computed: {
    // Handles the value prop in a "private" way
    // The "value" prop is the incoming v-model
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  }, // end of computed

  methods: {}, // End of Methods
}; // End of Export
</script>

<style scoped></style>
