<template>
  <v-app-bar app clipped-right color="primary" dark height="40px">
    <!--
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      -->
    <v-toolbar-title>Wij Zijn Open</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn small color="green" @click.stop="toggleDrawerRight(1)">Zoeken</v-btn>
    <v-btn small text @click.stop="toggleDrawerRight(2)">Registreer jouw zaak</v-btn>

    <v-app-bar-nav-icon @click.stop="toggleDrawerRight(1)"></v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
import { gLog } from '@/services/GLogger';
// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'hoAppToolbar',

  computed: {
    ...mapGetters('hoLayout', { drawerRight: 'getDrawerRight' }),

    apiKey() {
      return process.env.VUE_APP_MAPBOX_KEY;
    },
  },

  components: {},

  data: () => ({
    drawer: null,
    // drawerRight: null,
    right: false,
    left: false,

    location: [],
    geo: true,
    searchForm: true,
    height: 500,
    color: '#FFA500',
  }), // End of data

  methods: {
    ...mapActions('hoLayout', ['setDrawerRight', 'setAction']),

    async toggleDrawerRight(action) {
      await this.setDrawerRight();

      gLog('dddddddddddd', this.drawerRight);
      if (this.drawerRight) await this.setAction(action);
    },
  }, // End of Methods

  mounted() {},
};
</script>

<style scoped></style>
