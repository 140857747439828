<template>
  <span v-show="showItem">
    <!-- Start Button WITHOUT SubMenus -->
    <v-btn
      v-if="!toolbarItem.subMenus"
      text
      :class="toolbarItem.class"
      @click="btnChangeRouter(toolbarItem.routeName, toolbarItem.routeParams)"
      >{{ toolbarItem.title }}
    </v-btn>
    <!-- End Button WITHOUT SubMenus -->

    <!-- Start Button WITH SubMenus -->
    <v-menu v-if="toolbarItem.subMenus" :offset-y="offset">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :class="toolbarItem.class" text v-bind="attrs" v-on="on"> {{ toolbarItem.title }} </v-btn>
      </template>
      <v-list>
        <span v-for="(subMenu, s) in toolbarItem.subMenus" :key="s">
          <v-list-item @click="btnChangeRouter(subMenu.routeName, subMenu.routeParams)">
            <v-list-item-title>{{ subMenu.title }}</v-list-item-title>
          </v-list-item>
        </span>
      </v-list>
    </v-menu>
    <!-- End Button WITH SubMenus -->
  </span>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex';
import mixinApp from '@/mixins/App';
import { mapActions, mapGetters } from 'vuex';
import { gLog } from '@/services/GLogger';
export default {
  name: 'VgLayoutToolbarItem',

  mixins: [mixinApp],

  props: {
    toolbarItem: {
      type: [Object, Array],
      required: false,
      default: () => {},
    },
  }, // End of props

  computed: {
    ...mapGetters('login', { isLoggedIn: 'getIsLoggedIn' }), // End of MapGetters

    showItem() {
      if (this.toolbarItem.routeName == 'pageLogin' && this.isLoggedIn) return false;
      if (this.isLoggedIn && !this.toolbarItem.isPublic) return true;
      if (this.toolbarItem.isPublic) return true;
    },
  }, // End of computed

  components: {}, // End of components

  data: () => ({
    offset: true,
  }), // End of data

  methods: {
    async btnChangeRouter(pageName, params) {
      let result = await this.dsnChangeRouter(pageName, params);
    }, // End of btnChangeRouter
  }, // End of methods

  // --- Start HOOKS
  watch: {
    $route(to, from) {
      // reacts to route changes...
      const activeClass = `${'accent'}--${'text'}`;
      // this.toolbarItem.class = activeClass;

      this.toolbarItem.class = !this.toolbarItem.subMenus
        ? this.toolbarItem.routeName === to.name
          ? ''
          : activeClass
        : this.toolbarItem.subMenus.find(subMenu => subMenu.routeName === to.name)
        ? activeClass
        : '';
    },
  },

  mounted() {},
  // --- End HOOKS

  // --- START Hooks ---
};
</script>

<style scoped></style>
