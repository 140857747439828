import { name as packageName } from '../../package.json';

const CSSreset = 'background: white; color: black; display: none;';

export const gLog = (...params) => {
  let CSSset = 'background: green; color: white; display: block;';
  console.log(`%c[%s]%c  %o`, CSSset, packageName, CSSreset, ...params);
};

export const gWarn = (...params) => {
  let CSSset = 'background: orange; color: white; display: block;';
  console.log(`%c[%s]%c  %o`, CSSset, packageName, CSSreset, ...params);
};

export const gInfo = (...params) => {
  let CSSset = 'background: blue; color: white; display: block;';
  console.log(`%c[%s]%c  %o`, CSSset, packageName, CSSreset, ...params);
};
