<template>
  <h1>Search</h1>
</template>

<script>
import { gLog } from "@/services/GLogger";

// Vuex Store
import { mapActions, mapGetters } from "vuex";

export default {
  name: "vwHoSearch",

  computed: {}, // End of computed

  components: {}, // End of components

  data: () => ({}), // End of data

  methods: {}, // End of methods

  async mounted() {},
};
</script>

<style scoped></style>
