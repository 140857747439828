<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-btn x-small class="mr-2" color="blue" @click="btnGetById(25)">GetById</v-btn>
      <v-btn x-small class="mr-2" color="orange" @click="btnPutById(25)">PutById</v-btn>

      <v-btn x-small class="mr-2" color="purple" @click="btnList()">List</v-btn>

      <v-btn x-small class="mr-2" color="green" @click="btnPost()">Post (create new)</v-btn>

      <v-spacer></v-spacer>
    </v-toolbar>

    <!--
    
    -->
    <hr />
    <GHelperJson :gTitle="gHelperTitle" :gData="gHelperData" />
  </v-container>
</template>

<script>
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TabPerson',

  computed: {
    ...mapGetters('vgPerson', {
      vgPerson: 'getVgPerson',
      vgPersons: 'getVgPersons',
    }),
  }, // End of computed

  components: {}, // End of components

  data: () => ({
    gHelperTitle: '',
    gHelperData: {},

    // Form Data ...
  }), // End of data

  methods: {
    ...mapActions('vgPerson', ['setPersonById', 'updatePersonById', 'createPerson', 'listPersons']),

    async btnGetById(id) {
      await this.setPersonById({ id });
      this.gHelperTitle = 'Got Person By Id';
      this.gHelperData = this.vgPerson;
    },

    async btnPutById(id) {
      // For testing pruposes... first getting the user
      await this.setPersonById({ id });
      let person = this.vgPerson;

      // Now modifying the user...
      person.Biography15 = 'this is modifiedddddddddddddddddd';
      this.updatePersonById({ id, person });
      this.gHelperTitle = 'Put Person By Id';
      this.gHelperData = this.vgPerson;
    },

    async btnList() {
      let oSearch = {
        FirstName: '',
        LastName: 'AAAAA',
      };

      await this.listPersons(oSearch);
      this.gHelperTitle = 'ListPersons';
      this.gHelperData = this.vgPersons;
    },

    async btnPost() {
      let oPerson = {
        LastName: 'ZZZZZ',
        FirstName: 'AAB0',
        PlaceOfBirth: null,
        YearOfBirth: 0,
        PlaceOfDeath: null,
        YearOfDeath: 0,
        Biography15: '',
        PhotoFilename: null,
      };

      await this.createPerson(oPerson);
      this.gHelperTitle = 'Created Person';
      this.gHelperData = this.vgPerson;
    },
  }, // End of methods

  // ----- Start Hooks
  watch: {}, // End of watch

  async mounted() {},
};
</script>

<style scoped></style>
