/**
 * Generated     With : Geoit.Swagger.Parser.Generator.Node 
 *            Version : 0.3.30
 *                 On : 12/3/2021, 08:54:28
 * Parsed swaggerJSON 
 *              Title : VersteendeGetuigenissen.API
 *           Location : https://vg-beta.geo-it.be/api/swagger/docs/v1
 *            Version : v1
 *           API Root : https://vg-beta.geo-it.be/api
 *
 * @summary Service  file to handle Api Endpont Bibliography
 *
 * @link                https://vg-beta.geo-it.be/api/swagger/docs/v1
 * @file                Bibliography.js.
 * @author              Johnny Driesen <johnny.driesen@geoit.be>.
 * @version             0.3.30
 * @since               x.y.z
 * @copyright           Geo-IT bv
 */
 

import { HTTP, getExtraHeaders } from './base';
import { gLog } from '@/services/GLogger';

// const END_POINT = 'Bibliography';



// ------------ GET ---------------------------------------------
/** 
 * @function getBibliographyById 
 */


// -- cntEndpoints: 10 .:. /Bibliography/{id}
export const getBibliographyById = async (id,params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/Bibliography/${id}`;

		const fixedParams = {};
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.get(endpoint, { headers, params });
		
		
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ GET ---------------------------------------------
/** 
 * @function getGraveSheetBibliographyById 
 */


// -- cntEndpoints: 12 .:. /Bibliography/{id}/GraveSheetBibliography
export const getGraveSheetBibliographyById = async (id,params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/Bibliography/${id}/GraveSheetBibliography`;

		const fixedParams = {};
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.get(endpoint, { headers, params });
		
		
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ GET ---------------------------------------------
/** 
 * @function getBibliographyByPersonId 
 */


// -- cntEndpoints: 13 .:. /Bibliography/BibliographyByPersonId
export const getBibliographyByPersonId = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/Bibliography/BibliographyByPersonId`;

		const fixedParams = {};
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.get(endpoint, { headers, params });
		
		
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ POST ---------------------------------------------
/** 
 * @function postBibliography 
 */


// -- cntEndpoints: 9 .:. /Bibliography
export const postBibliography = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const { Identifier,Author,Title,PlacePublished,Publisher,YearPublished,SeriesPart,ArticleYearNumberTitle,Essay,EssayInstitute,ExhibitionCatalog } = params ;  
		*/

		const endpoint = `/Bibliography`;

		const fixedParams = {};
		
		
		
			const data = { ...fixedParams, ...params };
			const response = await HTTP.post(endpoint,  data , { headers });
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ POST ---------------------------------------------
/** 
 * @function postList 
 */


// -- cntEndpoints: 14 .:. /Bibliography/list
export const postList = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const { Author,PlacePublished,Publisher,Title,YearPublished,Pagination } = params ;  
		*/

		const endpoint = `/Bibliography/list`;

		const fixedParams = {};
		
		
		
			const data = { ...fixedParams, ...params };
			const response = await HTTP.post(endpoint,  data , { headers });
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ PUT ---------------------------------------------
/** 
 * @function putBibliographyById 
 */


// -- cntEndpoints: 11 .:. /Bibliography/{id}
export const putBibliographyById = async (id,params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const { Identifier,Author,Title,PlacePublished,Publisher,YearPublished,SeriesPart,ArticleYearNumberTitle,Essay,EssayInstitute,ExhibitionCatalog } = params ;  
		*/

		const endpoint = `/Bibliography/${id}`;

		const fixedParams = {};
		
		
		
		
		
			const data = { ...fixedParams, ...params };
			const response = await HTTP.put(endpoint,  data , { headers });
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};


