<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-btn x-small class="mr-2" color="blue" @click="btnGetById(1)">GetById</v-btn>
      <v-btn x-small class="mr-2" color="orange" @click="btnPutById(1)">PutById</v-btn>

      <v-spacer></v-spacer>
    </v-toolbar>

    <!--
    
    -->
    <hr />
    <GHelperJson :gTitle="gHelperTitle" :gData="gHelperData" />
  </v-container>
</template>

<script>
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TabBibliography',

  computed: {
    ...mapGetters('vgBibliography', {
      vgBibliography: 'getVgBibliography',
    }),
  }, // End of computed

  components: {}, // End of components

  data: () => ({
    gHelperTitle: '',
    gHelperData: {},

    // Form Data ...
  }), // End of data

  methods: {
    ...mapActions('vgBibliography', ['setBibliographyById', 'updateBibliographyById']),

    async btnGetById(id) {
      await this.setBibliographyById({ id });
      this.gHelperTitle = 'Got Bibliography By Id';
      this.gHelperData = this.vgBibliography;
    },

    async btnPutById(id) {
      // For testing pruposes... first getting the user
      await this.setBibliographyById({ id });
      let bibliography = this.vgBibliography;

      // Now modifying the user...
      bibliography.Title = 'Het kerkhof van Westrozebeke modifiedddddddddddddddddd';
      this.updateBibliographyById({ id, bibliography });
      this.gHelperTitle = 'Put Bibliography By Id';
      this.gHelperData = this.vgBibliography;
    },
  }, // End of methods

  // ----- Start Hooks
  watch: {}, // End of watch

  async mounted() {},
};
</script>

<style scoped></style>
