<template>
  <v-container>
    <h4>LDrawer</h4>
  </v-container>
</template>

<script>
export default {
  name: 'VgAppLeftDrawerPublic',

  computed: {}, // End of computed

  components: {}, // End of components

  data: () => ({}), // End of data

  methods: {}, // End of methods

  // -- Start HOOKS --
  watch: {},

  mounted() {},

  async activated() {},

  async beforeUpdate() {},

  async updated() {},
  // --  End HOOKS --
};
</script>

<style scoped></style>
