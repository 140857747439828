import Vue from 'vue';

//import Vuetify from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
import { i18n } from '@/plugins/i18n';
import colors from 'vuetify/lib/util/colors';

import VgIcon from '@/components/VgIcon';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },

  theme: {
    themes: {
      light: {
        // primary: '#00768e', // teal alike
        // secondary: '#ee726b', // pink alike
        // accent: '#f4e511',
        // error: colors.red.accent3,
        // background: colors.green.lighten5,
      },
      dark: {
        primary: colors.blue.lighten3,
        background: colors.indigo.base,
      },
    },
  },

  icons: {
    values: {
      vgIcon: {
        component: VgIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: 'vgIcon',
        },
      },
    },
  },
});
