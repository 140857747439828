// Backoffice
const routePath = '/store';
const routeName = 'pageStore';
const component = () => import('@/views/Store/Wrapper');

export default {
  path: `${routePath}`,
  name: routeName,
  components: {
    default: component,
  }, // End of components

  props: {
    default: true,
  }, // End of props

  meta: {}, // End of meta
};
