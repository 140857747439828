<template>
  <v-card>
    <v-card-title> this is the title </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <div id="mapContainer" class="basemap"></div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { gLog } from '@/services/GLogger';

import hoPlaces from '@/data/hoPlaces.json';

const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
// import mapboxgl from "mapbox-gl";

// Vuex Store
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'BaseMap',

  computed: {
    ...mapGetters('hoLayout', { mapboxApiKey: 'getMapboxApiKey' }),
  },

  data() {
    return {
      // accessToken: MAPBOX_ACCESS_TOKEN,
    };
  },
  mounted() {
    mapboxgl.accessToken = this.mapboxApiKey;

    new mapboxgl.Map({
      container: 'mapContainer',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [103.811279, 1.345399],
      zoom: 12,
      maxBounds: [
        [103.6, 1.1704753],
        [104.1, 1.4754753],
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.basemap {
  width: 100%;
  height: 100%;
}
</style>
