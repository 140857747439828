import { gLog } from '@/services/GLogger';

export default class gSelector {
  constructor(payload) {
    let { hasData = false, count = 0, _pageSize = 25, _CurrentPage = 0 } = payload;

    // this._meta = _meta;
    this.hasData = hasData;
    this.count = count;
    this._pageSize = _pageSize;
    this._CurrentPage = _CurrentPage;
  }

  mutate(Data, Pagination) {
    this.hasData = Data.length > 0;
    this.count = Pagination.Count;
    this._CurrentPage = Pagination.CurrentPage;
  }

  update(Data, Pagination) {
    this.hasData = Data.length > 0;
    this.count = Pagination.Count;
    this._CurrentPage = Pagination.CurrentPage;
  }
}
