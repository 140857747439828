import { gLog } from "@/services/GLogger";

export const state = {
  _layoutTimeout: process.env.VUE_APP_LAYOUT_TIMEOUT_MS || 500,
  _heightOuterContainer: 0,
  _heightSearchContainer: 0,
  _leftDrawerOpen: false,
  _mini: false,
};

export const getters = {
  getLayoutTimeout: (state) => state._layoutTimeout,
  getHeightOuterContainer: (state) => state._heightOuterContainer,
  getHeightSearchContainer: (state) => state._heightSearchContainer,
  getLeftDrawerOpen: (state) => state._leftDrawerOpen,
  getMini: (state) => state._mini,
};

export const mutations = {
  setHeightOuterContainer: (state, height) =>
    (state._heightOuterContainer = height),
  setHeightSearchContainer: (state, height) =>
    (state._heightSearchContainer = height),
  setLeftDrawerOpen: (state, status) => (state._leftDrawerOpen = status),
  toggleLeftDrawerOpen: (state, status) =>
    (state._leftDrawerOpen = !state._leftDrawerOpen),

  setMini: (state, status) => (state._mini = status),
};

export const actions = {
  setHeightSearchContainer: async ({ state, commit }, height) =>
    commit("setHeightSearchContainer", height),
  setHeightOuterContainer: async ({ state, commit }, height) =>
    commit("setHeightOuterContainer", height),
  setLeftDrawerOpen: async ({ state, commit }, status) =>
    commit("setLeftDrawerOpen", status),
  toggleLeftDrawerOpen: async ({ state, commit }, status) =>
    commit("toggleLeftDrawerOpen", null),

  setMini: async ({ state, commit }, status) => commit("setMini", status),
};
