<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-btn x-small class="mr-2" color="blue" @click="btnGetById(65)">GetById</v-btn>
      <v-spacer></v-spacer>
    </v-toolbar>

    <hr />
    <GHelperJson :gTitle="gHelperTitle" :gData="gHelperData" />
  </v-container>
</template>

<script>
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TabFiche',

  computed: {
    ...mapGetters('vgFiche', {
      vgFiche: 'getVgFiche',
    }),
  }, // End of computed

  components: {}, // End of components

  data: () => ({
    gHelperTitle: '',
    gHelperData: {},
  }), // End of data

  methods: {
    // ...mapActions('vgUser', ['setUsers', 'setUser']),
    ...mapActions('vgFiche', ['setFicheById']),

    async btnGetById(id) {
      await this.setFicheById({ id });
      this.gHelperTitle = 'Got Fiche By Id';
      this.gHelperData = this.vgFiche;
    },
  }, // End of methods

  // ----- Start Hooks
  watch: {}, // End of watch

  async mounted() {},
};
</script>

<style scoped></style>
