export const toolbarItems = [
  {
    title: 'Home',
    routeName: 'pageHome',
    routeParams: {
      drawerLeft: {
        show: true,
        env: 'public',
      },
      data: {
        reset: true,
      },
    },
    class: '',
    positioned: 'LEFT',
    isPublic: true,
  },

  {
    title: 'Store',
    routeName: 'pageStore',
    routeParams: {
      drawerLeft: {
        show: true,
        env: 'public',
      },
    },
    class: '',
    positioned: 'LEFT',
    isPublic: true,
  },
];
