import { render, staticRenderFns } from "./VgFicheItemParagraph.vue?vue&type=template&id=392930f9&scoped=true&"
import script from "./VgFicheItemParagraph.vue?vue&type=script&lang=js&"
export * from "./VgFicheItemParagraph.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "392930f9",
  null
  
)

export default component.exports