/**
 * Generated     With : Geoit.Swagger.Parser.Generator.Node 
 *            Version : 0.3.30
 *                 On : 12/3/2021, 08:54:28
 * Parsed swaggerJSON 
 *              Title : VersteendeGetuigenissen.API
 *           Location : https://vg-beta.geo-it.be/api/swagger/docs/v1
 *            Version : v1
 *           API Root : https://vg-beta.geo-it.be/api
 *
 * @summary Service  file to handle Api Endpont Person
 *
 * @link                https://vg-beta.geo-it.be/api/swagger/docs/v1
 * @file                Person.js.
 * @author              Johnny Driesen <johnny.driesen@geoit.be>.
 * @version             0.3.30
 * @since               x.y.z
 * @copyright           Geo-IT bv
 */
 

import { HTTP, getExtraHeaders } from './base';
import { gLog } from '@/services/GLogger';

// const END_POINT = 'Person';



// ------------ GET ---------------------------------------------
/** 
 * @function getPersonById 
 */


// -- cntEndpoints: 104 .:. /Person/{id}
export const getPersonById = async (id,params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/Person/${id}`;

		const fixedParams = {};
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.get(endpoint, { headers, params });
		
		
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ POST ---------------------------------------------
/** 
 * @function postPerson 
 */


// -- cntEndpoints: 103 .:. /Person
export const postPerson = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const { Identifier,LastName,FirstName,PlaceOfBirth,YearOfBirth,PlaceOfDeath,YearOfDeath,Biography15,PhotoFilename } = params ;  
		*/

		const endpoint = `/Person`;

		const fixedParams = {};
		
		
		
			const data = { ...fixedParams, ...params };
			const response = await HTTP.post(endpoint,  data , { headers });
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ POST ---------------------------------------------
/** 
 * @function postList 
 */


// -- cntEndpoints: 106 .:. /Person/list
export const postList = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const { FirstName,LastName,Pagination } = params ;  
		*/

		const endpoint = `/Person/list`;

		const fixedParams = {};
		
		
		
			const data = { ...fixedParams, ...params };
			const response = await HTTP.post(endpoint,  data , { headers });
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ PUT ---------------------------------------------
/** 
 * @function putPersonById 
 */


// -- cntEndpoints: 105 .:. /Person/{id}
export const putPersonById = async (id,params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const { Identifier,LastName,FirstName,PlaceOfBirth,YearOfBirth,PlaceOfDeath,YearOfDeath,Biography15,PhotoFilename } = params ;  
		*/

		const endpoint = `/Person/${id}`;

		const fixedParams = {};
		
		
		
		
		
			const data = { ...fixedParams, ...params };
			const response = await HTTP.put(endpoint,  data , { headers });
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};


