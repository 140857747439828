<template>
  <v-bottom-navigation app>
    <v-btn>
      <span>Recent</span>
      <v-icon>mdi-history</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
// Services
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'VgAppBottombar',

  mixins: [], // End of mixins

  computed: {}, // End of computed

  components: {}, // End of components

  data: () => ({}), // End of data

  methods: {}, // End of methods

  // -- Start HOOKS --
  watch: {},

  mounted() {},

  async activated() {},

  async beforeUpdate() {},

  async updated() {},
  // --  End HOOKS --
};
</script>

<style scoped></style>
