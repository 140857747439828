export const state = {
  isLoggedIn: false,
};

export const getters = {
  getIsLoggedIn: state => state.isLoggedIn,
};

export const mutations = {
  setIsLoggedIn: (state, payload) => {
    state.isLoggedIn = payload;
  },
};

export const actions = {
  setIsLoggedIn: ({ commit }, payload) => commit('setIsLoggedIn', payload),
};
