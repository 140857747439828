<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-btn x-small class="mr-2" color="blue" @click="btnGetById(1)">GetById (1)</v-btn>
      <v-btn x-small class="mr-2" color="blue" @click="btnGetArrayWithNiscodes(1)">Get Array With Niscodes (1)</v-btn>
      <v-btn x-small class="mr-2" color="blue" @click="btnGetArrayWithNiscodesAndMunicipalities(1)"
        >Get Array With Niscodes And Municipalities (1)</v-btn
      >
      <v-spacer></v-spacer>
    </v-toolbar>

    <hr />
    <GHelperJson :gTitle="gHelperTitle" :gData="gHelperData" />
  </v-container>
</template>

<script>
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TabRegion',

  computed: {
    ...mapGetters('vgRegion', {
      vgRegion: 'getVgRegion',
      vgRegionNiscodes: 'getVgRegionNiscodes',
      vgNiscodesAndMunicipalities: 'getVgNiscodesAndMunicipalities',
    }),
  }, // End of computed

  components: {}, // End of components

  data: () => ({
    gHelperTitle: '',
    gHelperData: {},

    // Form Data ...
  }), // End of data

  methods: {
    // ...mapActions('vgUser', ['setUsers', 'setUser']),
    ...mapActions('vgRegion', ['setRegionById']),

    async btnGetById(id) {
      await this.setRegionById({ id });
      this.gHelperTitle = 'Got Region By Id';
      this.gHelperData = this.vgRegion;
    },

    async btnGetArrayWithNiscodes(id) {
      await this.setRegionById({ id });
      this.gHelperTitle = 'Array with RegionNiscodes';
      this.gHelperData = this.vgRegionNiscodes;
    },

    async btnGetArrayWithNiscodesAndMunicipalities(id) {
      await this.setRegionById({ id });
      this.gHelperTitle = 'Array with Niscodes and Municipalities';
      this.gHelperData = this.vgNiscodesAndMunicipalities;
    },
  }, // End of methods

  // ----- Start Hooks
  watch: {}, // End of watch

  async mounted() {},
};
</script>

<style scoped></style>
