<template>
  <div>
    <v-tabs v-model="tab">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <!-- users -->
      <v-tab>User</v-tab>
      <v-tab>Region</v-tab>
      <v-tab>Person</v-tab>
      <v-tab>Bibliography</v-tab>
      <v-tab>Fiche (GraveSheet)</v-tab>
      <v-tab>Deceased</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <!-- User -->
      <v-tab-item>
        <cmptTabUser />
      </v-tab-item>

      <!-- Region -->
      <v-tab-item>
        <cmptTabRegion />
      </v-tab-item>

      <!-- Person -->
      <v-tab-item>
        <cmptTabPerson />
      </v-tab-item>

      <!-- Bibliography -->
      <v-tab-item>
        <cmptTabBibliography />
      </v-tab-item>

      <!-- Fiche -->
      <v-tab-item>
        <cmptTabFiche />
      </v-tab-item>

      <!-- Deceased -->
      <v-tab-item>
        <cmptTabDeceased />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Store',

  computed: {}, // End of computed

  components: {
    cmptTabUser: () => import('./TabUser'),
    cmptTabRegion: () => import('./TabRegion'),
    cmptTabPerson: () => import('./TabPerson'),
    cmptTabBibliography: () => import('./TabBibliography'),
    cmptTabFiche: () => import('./TabFiche'),
    cmptTabDeceased: () => import('./TabDeceased'),
  }, // End of components

  data: () => ({
    tab: null,
  }), // End of data

  methods: {}, // End of methods

  // ----- Start Hooks
  watch: {}, // End of watch

  async mounted() {},
};
</script>

<style scoped></style>
