<template>
  <v-container fluid class="full-height">
    <hoLocator />

    <!--
    <v-row justify="center" align="center">
      
      <v-col class="shrink">
        
      <v-col cols="12">
        
      </v-col>
    </v-row>
    -->
  </v-container>
</template>

<script>
// Services
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HoAppContent',

  computed: {
    baseUrl() {
      return `baseUrl : ${process.env.VUE_APP_ROOT_API}`;
    },
  }, // End of computed

  components: {}, // End of components

  data: () => ({}), // End of data

  methods: {}, // End of methods

  // -- Start HOOKS --
  watch: {},

  mounted() {},

  async activated() {},

  async beforeUpdate() {},

  async updated() {},
  // --  End HOOKS --
};
</script>

<style scoped></style>
