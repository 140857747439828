<template>
  <!--
  <div>
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in arLanguages" :key="`lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
    <p>Translated Players Singular : {{ $t('players.singular') }}</p>
    <p>Translated Teams Plural : {{ $t('teams.plural') }}</p>
    <p>Team Info... : {{ $t('teams.info', { name: 'New York Yankees', nrOfPlayers: 23 }) }}</p>
  </div>
  -->

  <v-card>
    <v-card-title>Language Helper</v-card-title>
    <v-card-text>
      <v-select v-model="$i18n.locale" :items="arLanguages" label="Select a Language" dense outlined></v-select>
      <p>Translated Players Singular : {{ $t('players.singular') }}</p>
      <p>Translated Teams Plural : {{ $t('teams.plural') }}</p>
      <p>Team Info... : {{ $t('teams.info', { name: 'New York Yankees', nrOfPlayers: 23 }) }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'GHelperI18n',

  data: () => ({
    arLanguages: ['en', 'nl'],
    items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
  }), // End of data
};
</script>
