/**
 * Generated     With : Geoit.Swagger.Parser.Generator.Node 
 *            Version : 0.3.30
 *                 On : 12/3/2021, 08:54:28
 * Parsed swaggerJSON 
 *              Title : VersteendeGetuigenissen.API
 *           Location : https://vg-beta.geo-it.be/api/swagger/docs/v1
 *            Version : v1
 *           API Root : https://vg-beta.geo-it.be/api
 *
 * @summary Service  file to handle Api Endpont Material
 *
 * @link                https://vg-beta.geo-it.be/api/swagger/docs/v1
 * @file                Material.js.
 * @author              Johnny Driesen <johnny.driesen@geoit.be>.
 * @version             0.3.30
 * @since               x.y.z
 * @copyright           Geo-IT bv
 */
 

import { HTTP, getExtraHeaders } from './base';
import { gLog } from '@/services/GLogger';

// const END_POINT = 'Material';



// ------------ GET ---------------------------------------------
/** 
 * @function getMaterial 
 */


// -- cntEndpoints: 94 .:. /Material
export const getMaterial = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/Material`;

		const fixedParams = {};
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.get(endpoint, { headers, params });
		
		
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ GET ---------------------------------------------
/** 
 * @function getArchitectureMaterials 
 */


// -- cntEndpoints: 95 .:. /Material/ArchitectureMaterials
export const getArchitectureMaterials = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/Material/ArchitectureMaterials`;

		const fixedParams = {};
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.get(endpoint, { headers, params });
		
		
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ GET ---------------------------------------------
/** 
 * @function getSculptureMaterials 
 */


// -- cntEndpoints: 96 .:. /Material/SculptureMaterials
export const getSculptureMaterials = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/Material/SculptureMaterials`;

		const fixedParams = {};
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.get(endpoint, { headers, params });
		
		
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ GET ---------------------------------------------
/** 
 * @function getSculptureSmallMaterials 
 */


// -- cntEndpoints: 97 .:. /Material/SculptureSmallMaterials
export const getSculptureSmallMaterials = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/Material/SculptureSmallMaterials`;

		const fixedParams = {};
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.get(endpoint, { headers, params });
		
		
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};


