import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// Import all of the resource routes files in a dynamic way
// https://www.smgaweb.com/blog/dynamically-generating-vue-router-routes-from-directory-structure

const requireContext = require.context('./', true, /index.js$/i);

const loadRoutes = () => {
  return requireContext
    .keys()
    .map(requireContext) // import module
    .map(m => m.default) // get `default` export from each resolved module
    .filter(m => m != null); // remove the undefined ones ...
};

let routes = [];
loadRoutes().forEach(r => routes.push(r));

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
