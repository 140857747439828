<template>
  <div>
    <span class="text-body-2 font-weight-regular font-italic">{{ vgTitle }} </span>
    <span class="text-body-2 font-weight-regular">
      {{ vgSubtitle }}
      <!--
      <v-btn v-if="vgText" class="" x-small text icon color="primary">
        <v-icon>mdi-information</v-icon>
      </v-btn>
      -->
    </span>
    <!--
    <vgFicheItemText :vgText="vgText" />
    -->
  </div>
</template>

<script>
export default {
  name: 'vgFicheItemBlock',

  props: {
    vgTitle: {
      type: String,
      required: false,
      default: () => '',
    },

    vgSubtitle: {
      type: String,
      required: false,
      default: () => '',
    },

    vgText: {
      type: String,
      required: false,
      default: () => '',
    },
  }, // End of props

  computed: {}, // end of Computed

  methods: {}, // End of Methods
}; // End of Export
</script>

<style scoped></style>
