import axios from 'axios';

const baseURL = process.env.VUE_APP_ROOT_API;

import { gLog } from '@/services/GLogger';

export const getExtraHeaders = () => {
  let headers = {};
  return headers;
};

export const HTTP = axios.create({
  baseURL: baseURL,
  // HTTP headers are case-insensitive,
  // so whether you use 'authorization' or 'Authorization' doesn't matter.
  headers: {
    // Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  // !!! has to be SET to true when working locally !!
  withCredentials: true,
});
