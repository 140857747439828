// vuex vgUser

import { gLog } from '@/services/GLogger';
// Classes
import gSelector from '@/classes/clsSelector';

import {
  postLogin,
  postUser,
  getCurrentLoggedInUser,
  getUserById,
  putUserById,
  postList,
  postIsUserAdminForMunicipalty,
} from '@/services/http/User';

export const state = {
  _vgUserIsLoggedIn: false,
  _vgUserLoggedIn: null,
  _vgUser: null,
  _vgUsers: [],
  _selector: new gSelector({ hasData: false, count: 0, _pageSize: 25, _CurrentPage: 0 }),
};

export const getters = {
  getVgUser: state => state._vgUser,

  getVgUserLoggedIn: state => state._vgUserLoggedIn,
  getVgUserIsLoggedIn: state => state._vgUserIsLoggedIn,
  getVgUsers: state => state._vgUsers,
  getSelector: state => state._selector,
};

export const mutations = {
  setUser: (state, user) => (state._vgUser = user),
  setUserLoggedIn: (state, user) => (state._vgUserLoggedIn = user),

  setUserIsLoggedIn: (state, bSuccess) => {
    if (!bSuccess) state._vgUserLoggedIn = {};
    state._vgUserIsLoggedIn = bSuccess;
  },

  setUsers: (state, payload) => {
    let { Data, Pagination } = payload;
    state._selector.mutate(Data, Pagination);
    state._vgUsers.push(...Data);
  },
};

export const actions = {
  logoutUser: async ({ state, commit }, payload) => {
    // we're forcing the LoggedIn Status to false
    return commit('setUserIsLoggedIn', false);
  },

  loginUser: async ({ state, commit, dispatch }, payload) => {
    const { UserName, Password } = payload;

    let bSuccess = await postLogin(payload);
    await dispatch('setUserLoggedIn', null);
    return commit('setUserIsLoggedIn', bSuccess);
  },

  setUserLoggedIn: async ({ state, commit }, payload) => {
    let user = await getCurrentLoggedInUser();
    return commit('setUserLoggedIn', user);
  },

  setUserById: async ({ state, commit }, payload) => {
    // let user = await getCurrentLoggedInUser();
    let { id } = payload;
    let params = {};
    let user = await getUserById(id, params);
    return commit('setUser', user);
  },

  updateUserById: async ({ state, commit }, payload) => {
    // let user = await getCurrentLoggedInUser();
    let { id, user } = payload;
    let params = {};
    let updatedUser = await putUserById(id, user);
    return commit('setUser', updatedUser);
  },

  setUser: async ({ state, commit }, payload) => {
    let { id } = payload;
    let params = {};
    let user = await getUserById(id, params);
    return commit('setUser', user);
  },

  /*
  listUsers: async ({ dispatch, state, commit }, payload) => {
    try {
      // let {  } = payload;

      let Pagination = { Page: 1, PageSize: 25 };
      let params = { ...payload, Pagination };

      let res = await postList(params);
      return commit('setPersons', res);
    } catch (e) {
      console.log(e.message);
    }
  },
  */

  createUser: async ({ state, commit }, payload) => {
    let user = await postUser(payload);
    return commit('setUser', user);
  },

  setUsers: async ({ dispatch, state, commit }, payload) => {
    try {
      // let {} = payload;

      // let Pagination = { Page: state._selector._CurrentPage + 1, PageSize: state._selector._pageSize };
      let Pagination = { Page: 1, PageSize: 25 };
      let res = await postAllUsers({ Pagination });
      return commit('setUsers', res);
    } catch (e) {
      console.log(e.message);
    }
  },

  isUserAllowed: async ({ dispatch, state, commit }, payload) => {
    let aaa = {
      UserName: 'johnny',
      NisCode: '36008',
    };

    let res = await postIsUserAdminForMunicipalty(aaa);
    gLog('rrrrrrrrrrrr', res);
  },

  resetSelector: async ({ state, commit }, payload) => commit('resetSelector', payload),
};
