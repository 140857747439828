<template>
  <v-app id="inspire">
    <hoApp />
  </v-app>
</template>

<script>
import { gLog } from "@/services/GLogger";
// Vuex Store

export default {
  name: "App",

  computed: {}, // End of computed

  components: {}, // End of components

  data: () => ({}), // End of data

  methods: {}, // End of methods
};
</script>

<style scoped></style>
