<template>
  <v-card>
    <v-card-title>Module Test</v-card-title>

    <v-card-text> IsLoggedIn : {{ isLoggedIn }} </v-card-text>
    <v-card-actions>
      <v-btn small color="green" @click="btnLogin">SetIsLoggedIn</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'GHelperModules',

  props: {}, // End of props

  computed: {
    ...mapGetters('login', { isLoggedIn: 'getIsLoggedIn' }), // End of MapGetters
  }, // End of computed

  components: {},

  data: () => ({
    email: '',
    select: null,
    items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
    checkbox: null,
  }), // End of Data

  methods: {
    ...mapActions('login', ['setIsLoggedIn']),

    btnLogin() {
      this.setIsLoggedIn(true);
    },
  }, // End of methods

  // **** Start HOOKS ****

  beforeCreate() {}, // End of created

  created() {}, // End of created

  beforeMount() {}, // End of created

  mounted() {}, // End of mounted

  beforeUpdate() {}, // End of created

  updated() {}, // End of updated

  activated() {}, // End of activated

  deactivated() {}, // End of activated

  beforeDestroy() {}, // End of beforeDestroy

  destroyed() {}, // End of beforeDestroy

  // **** End HOOKS ****
};
// End of Export
</script>

<style scoped></style>
