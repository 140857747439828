<template>
  <div>
    <v-snackbar top right v-model="snackbar" :multi-line="multiLine">
      <span content-class="caption">{{ snackbarText }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <hoAppDrawerRight />

    <hoAppToolbar />

    <v-main>
      <HoAppContent />
    </v-main>

    <HoAppFooter />
  </div>
</template>

<script>
import { gLog } from '@/services/GLogger';
// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'hoApp',

  computed: {}, // End of computed

  components: {}, // End of components

  data: () => ({
    snackbar: false,
    multiLine: true,
    snackbarText: 'johnny',
  }), // End of data

  methods: {}, // End of methods
};
</script>

<style scoped></style>
