// vuex vgBibliography

import { gLog } from '@/services/GLogger';
// Classes
import gSelector from '@/classes/clsSelector';

import { postBibliography, getBibliographyById, putBibliographyById } from '@/services/http/Bibliography';

export const state = {
  _vgBibliography: null,
  _vgBibliographies: [],
  _selector: new gSelector({ hasData: false, count: 0, _pageSize: 25, _CurrentPage: 0 }),
};

export const getters = {
  getVgBibliography: state => state._vgBibliography,
  getVgBibliographies: state => state._vgBibliographies,
  getSelector: state => state._selector,
};

export const mutations = {
  setBibliography: (state, bibliography) => (state._vgBibliography = bibliography),

  setBibliographies: (state, payload) => {
    let { Data, Pagination } = payload;
    state._selector.mutate(Data, Pagination);
    state._vgBibliographies.push(...Data);
  },
};

export const actions = {
  setBibliographyById: async ({ state, commit }, payload) => {
    // let user = await getCurrentLoggedInUser();
    let { id } = payload;
    let params = {};
    let bibliography = await getBibliographyById(id, params);
    return commit('setBibliography', bibliography);
  },

  updateBibliographyById: async ({ state, commit }, payload) => {
    // let user = await getCurrentLoggedInUser();
    let { id, bibliography } = payload;
    let params = {};
    let updatedBibliography = await putBibliographyById(id, bibliography);
    return commit('setBibliography', updatedBibliography);
  },

  setBibliographies: async ({ dispatch, state, commit }, payload) => {
    try {
      // let {} = payload;

      // let Pagination = { Page: state._selector._CurrentPage + 1, PageSize: state._selector._pageSize };
      let Pagination = { Page: 1, PageSize: 25 };
      let res = await postBibliography({ Pagination });
      return commit('setBibliographies', res);
    } catch (e) {
      console.log(e.message);
    }
  },

  resetSelector: async ({ state, commit }, payload) => commit('resetSelector', payload),
};
