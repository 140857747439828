<template>
  <v-app id="app">
    <vg-app-drawer />
    <vg-app-topbar v-if="false" />
    <vg-app-toolbar />
    <vg-app-content />
    <vg-app-footer />
    <vg-app-bottombar v-if="false" />
  </v-app>
</template>

<script>
// Services
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'vgApp',

  computed: {}, // End of computed

  methods: {},

  // ---- Start HOOKS

  watch: {}, // End of watch

  async mounted() {
    // Setting the language ...
    this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE || 'nl';
  },
  // ---- End HOOKS
};
</script>
