<template>
  <v-navigation-drawer :value="drawerRight" app clipped right>
    <span v-if="action == 1">
      <hoSearch />
    </span>
    <span v-if="action == 2">
      <hoRegister />
    </span>
    <!--
    <v-list dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Right Drawer Contenttttt</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    -->
  </v-navigation-drawer>
</template>

<script>
import { gLog } from "@/services/GLogger";
// Vuex Store
import { mapActions, mapGetters } from "vuex";

export default {
  name: "HoAppDrawerRight",

  computed: {
    ...mapGetters("hoLayout", {
      drawerRight: "getDrawerRight",
      action: "getAction",
    }),
  },

  components: {},

  data: () => ({}), // End of data

  methods: {}, // End of methods

  mounted() {
    gLog("action", this.action);
  },
};
</script>

<style scoped></style>
