// Load library
import Vue from 'vue';
import Vuex from 'vuex';
import { gLog } from '@/services/GLogger';

Vue.use(Vuex);

// Load store modules dynamically.
const requireContext = require.context('./modules', true, /.*\.js$/);

const modules = requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    if (!module.namespaced) module.namespaced = true;
    // gLog('Registered Module:', name);
    return { ...modules, [name]: module };
  }, {});

export default new Vuex.Store({ modules });
