import Vue from 'vue';

// We need the translations ...
import { i18n } from '@/plugins/i18n';

// Import Needed Functions from vee-validate
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate';

// Register the necessary Validation Components Globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  },
});

import { required, email, min, max } from 'vee-validate/dist/rules';
// Install required rule and message.
extend('required', { ...required }); // or extend("required", required);

// Install email rule and message.
extend('email', { ...email }); // or  extend("email", email);

// Install min rule and message.
extend('min', { ...min }); // or  extend("min", min);

// Install max rule and message.
extend('max', { ...max }); // or  extend("max", max);
