<template>
  <span>
    <span v-for="(item, i) in toolbarItems" :key="i">
      <vg-app-toolbar-item v-if="item.positioned === positioned" :toolbarItem="item" />
    </span>
  </span>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex';
import mixinApp from '@/mixins/App';
import { gLog } from '@/services/GLogger';
export default {
  name: 'VgToolbarItems',

  mixins: [mixinApp],

  props: {
    toolbarItems: {
      type: Array,
      required: false,
      default: () => [],
    },

    positioned: {
      type: String,
      required: false,
      default: () => 'LEFT', // or "RIGHT"
    },
  }, // End of props

  computed: {}, // End of computed

  components: {}, // End of components

  data: () => ({}), // End of data

  methods: {}, // End of methods

  // --- Start HOOKS
  watch: {},

  mounted() {},
  // --- End HOOKS

  // --- START Hooks ---
};
</script>

<style scoped></style>
