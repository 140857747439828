/**
 * Generated     With : Geoit.Swagger.Parser.Generator.Node 
 *            Version : 0.3.30
 *                 On : 12/3/2021, 08:54:28
 * Parsed swaggerJSON 
 *              Title : VersteendeGetuigenissen.API
 *           Location : https://vg-beta.geo-it.be/api/swagger/docs/v1
 *            Version : v1
 *           API Root : https://vg-beta.geo-it.be/api
 *
 * @summary Service  file to handle Api Endpont DeceasedPersons
 *
 * @link                https://vg-beta.geo-it.be/api/swagger/docs/v1
 * @file                DeceasedPersons.js.
 * @author              Johnny Driesen <johnny.driesen@geoit.be>.
 * @version             0.3.30
 * @since               x.y.z
 * @copyright           Geo-IT bv
 */
 

import { HTTP, getExtraHeaders } from './base';
import { gLog } from '@/services/GLogger';

// const END_POINT = 'DeceasedPersons';



// ------------ GET ---------------------------------------------
/** 
 * @function getDeceasedPersonsById 
 */


// -- cntEndpoints: 33 .:. /DeceasedPersons/{id}
export const getDeceasedPersonsById = async (id,params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/DeceasedPersons/${id}`;

		const fixedParams = {};
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.get(endpoint, { headers, params });
		
		
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ GET ---------------------------------------------
/** 
 * @function getGraveSheetDeceasedPersonById 
 */


// -- cntEndpoints: 37 .:. /DeceasedPersons/GraveSheetDeceasedPersonById
export const getGraveSheetDeceasedPersonById = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/DeceasedPersons/GraveSheetDeceasedPersonById`;

		const fixedParams = {};
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.get(endpoint, { headers, params });
		
		
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ GET ---------------------------------------------
/** 
 * @function getMunicipaltyDeceased 
 */


// -- cntEndpoints: 38 .:. /DeceasedPersons/MunicipaltyDeceased
export const getMunicipaltyDeceased = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/DeceasedPersons/MunicipaltyDeceased`;

		const fixedParams = {};
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.get(endpoint, { headers, params });
		
		
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ POST ---------------------------------------------
/** 
 * @function postGraveSheetBuriedPerson 
 */


// -- cntEndpoints: 35 .:. /DeceasedPersons/GraveSheetBuriedPerson
export const postGraveSheetBuriedPerson = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const { GraveSheetVid,Person } = params ;  
		*/

		const endpoint = `/DeceasedPersons/GraveSheetBuriedPerson`;

		const fixedParams = {};
		
		
		
			const data = { ...fixedParams, ...params };
			const response = await HTTP.post(endpoint,  data , { headers });
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ POST ---------------------------------------------
/** 
 * @function postList 
 */


// -- cntEndpoints: 39 .:. /DeceasedPersons/list
export const postList = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const { Niscodes,FirstName,LastName,FreeSearchTerm,Locations,GraveSheetVid,Includes,Pagination } = params ;  
		*/

		const endpoint = `/DeceasedPersons/list`;

		const fixedParams = {};
		
		
		
			const data = { ...fixedParams, ...params };
			const response = await HTTP.post(endpoint,  data , { headers });
		
		
		
		
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ DELETE ---------------------------------------------
/** 
 * @function deleteDeceasedPersonBibliography 
 */


// -- cntEndpoints: 34 .:. /DeceasedPersons/DeceasedPersonBibliography
export const deleteDeceasedPersonBibliography = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/DeceasedPersons/DeceasedPersonBibliography`;

		const fixedParams = {};
		
		
		
		
		
		
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.delete(endpoint, { headers, params });
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};





// ------------ DELETE ---------------------------------------------
/** 
 * @function deleteGraveSheetBuriedPerson 
 */


// -- cntEndpoints: 36 .:. /DeceasedPersons/GraveSheetBuriedPerson
export const deleteGraveSheetBuriedPerson = async (params = {} ) => {
	try {
		const headers = getExtraHeaders();

		/*
		const {  } = params ;  
		*/

		const endpoint = `/DeceasedPersons/GraveSheetBuriedPerson`;

		const fixedParams = {};
		
		
		
		
		
		
		
			params = { ...fixedParams, ...params };
			const response = await HTTP.delete(endpoint, { headers, params });
		
		return response.data;
	} catch (error) {
	console.error('error', error);
	}
};


