<template>
  <span v-resize="onResize" class="caption">{{ $vuetify.breakpoint.name }} .:. {{ windowSize }}</span>
</template>
<script>
export default {
  name: 'GHelperResize',

  props: {},

  computed: {},

  data: () => ({
    windowSize: { x: 0, y: 0 },
  }),

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },

  // --- Start Hooks
  mounted() {
    this.onResize();
  },
  // --- End Hooks
};
</script>
