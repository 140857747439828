import { gLog } from '@/services/GLogger';

export const state = {
  _arTimings: [],
  _timing: {
    _tStart: 0,
    _tStop: 0,
    _tName: 'unknown',
    _tExec: 'weet niks',
  },
};

export const getters = {
  getTExec: state => payload => {
    let { tName } = payload;
    let idx = state._arTimings.findIndex(timing => timing._tName === tName);
    return idx === -1 ? 'Unknown timing' : state._arTimings[idx]._tExec;
  },
};

export const mutations = {
  setTStart: (state, payload) => {
    let { tName } = payload;
    let _tStart = performance.now();
    let newTiming = {
      _tStart,
      _tStop: 0,
      _tName: tName,
    };
    // Check if the name already exists ...
    let idx = state._arTimings.findIndex(timing => timing._tName === tName);
    idx === -1 ? state._arTimings.push(newTiming) : (state._arTimings[idx] = newTiming);
  },

  setTStop: (state, payload) => {
    let { tName } = payload;
    let _tStop = performance.now();

    // Check if the array Element with tName exists ...
    let idx = state._arTimings.findIndex(timing => timing._tName === tName);

    if (idx === -1) {
      gLog('ERROR in timing SetTStop... timingName not found', tName);
    } else {
      state._arTimings[idx]._tStop = _tStop;
      let timing = state._arTimings[idx];
      let tExecute = (timing._tStop - timing._tStart).toString().split('.')[0];
      state._arTimings[idx]._tExec = `${tName} took ${tExecute} ms`;
    }
  },
};

export const actions = {
  setTStart: async ({ commit }, payload) => {
    let { tName } = payload;
    return commit('setTStart', { tName });
  },

  setTStop: async ({ commit }, payload) => {
    let { tName } = payload;
    return commit('setTStop', { tName });
  },
};
