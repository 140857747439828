<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="576.000000pt"
    height="610.000000pt"
    viewBox="0 0 576.000000 610.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,610.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path
        d="M4100 5767 c-19 -9 -60 -83 -172 -309 l-147 -298 -123 64 c-265 138
-480 190 -778 190 -91 0 -201 -6 -245 -13 -365 -58 -683 -219 -940 -476 -250
-250 -400 -539 -473 -910 -13 -70 -16 -274 -19 -1622 l-4 -1542 -53 -24 c-68
-31 -122 -86 -155 -156 -21 -47 -26 -74 -29 -169 -5 -128 0 -153 30 -182 22
-20 27 -20 1888 -20 1861 0 1866 0 1888 20 30 29 35 54 30 182 -3 95 -8 122
-29 169 -33 70 -87 125 -156 156 l-53 24 0 1230 c0 676 2 1229 4 1229 3 0 86
-43 185 -95 100 -52 193 -95 207 -95 29 0 74 41 74 67 0 10 -34 214 -76 453
l-76 435 326 320 c353 346 352 345 304 400 -22 24 -39 28 -448 85 -234 33
-435 62 -448 65 -18 4 -59 81 -220 408 -165 334 -203 404 -226 415 -33 15 -35
15 -66 -1z m401 -955 c9 -6 181 -34 380 -62 200 -28 372 -54 383 -58 15 -6
-31 -55 -258 -276 -155 -151 -279 -279 -282 -293 -4 -13 23 -193 60 -400 36
-207 64 -378 63 -380 -2 -1 -64 29 -138 68 -422 223 -561 291 -583 285 -12 -3
-172 -85 -355 -182 -183 -97 -335 -174 -337 -171 -3 2 22 164 56 359 33 195
60 372 60 394 0 39 -6 45 -277 309 -153 148 -279 272 -281 275 -1 4 163 30
365 60 203 29 382 59 398 65 33 13 60 63 280 520 l97 200 176 -351 c96 -193
183 -356 193 -362z m-1463 437 c219 -23 461 -104 619 -205 l53 -34 -16 -33
-16 -33 -432 -64 c-237 -34 -442 -68 -456 -74 -32 -15 -55 -56 -47 -87 4 -14
141 -154 330 -336 l323 -312 -74 -439 c-45 -262 -72 -450 -68 -466 7 -30 58
-60 87 -51 10 4 193 98 405 211 211 112 388 204 392 204 4 0 65 -30 135 -66
l127 -65 0 -1270 0 -1269 -94 0 c-87 0 -95 -2 -120 -26 -15 -16 -26 -38 -26
-54 0 -16 11 -38 26 -54 26 -26 28 -26 176 -26 170 0 201 -9 246 -68 21 -28
27 -48 30 -103 l4 -69 -1762 0 -1762 0 4 69 c3 55 9 75 30 103 56 73 -56 68
1445 68 1044 1 1352 3 1364 13 44 34 47 89 7 127 -22 20 -30 20 -1315 20
l-1293 0 0 1470 c0 965 4 1504 11 1568 37 345 177 644 418 896 324 336 787
506 1249 455z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'vg-icon',
};
</script>
