<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    app
    clipped
    :height="heightOuterContainer"
    class="mt-3 ml-3 mr-3"
    :width="drawerWidth"
  >
    <component :is="drawerContent"></component>

    <template v-slot:append v-if="showLogout">
      <div class="pa-2">
        <v-btn block @click="btnLogout()">
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import mixinApp from '@/mixins/App';

import { gLog } from '@/services/GLogger';

import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'VgAppDrawer',

  mixins: [mixinApp],

  props: {}, // End of props

  computed: {
    ...mapGetters('layout', { leftDrawerOpen: 'getLeftDrawerOpen', getMini: 'getMini', heightOuterContainer: 'getHeightOuterContainer' }),

    outerContainerColor() {
      return 'primary';
    },
  }, // End of computed

  components: {}, // End of components

  data: () => ({
    drawer: true,

    drawerPublic: () => import('./Drawers/VgAppLeftDrawerPublic'),

    showLogout: false,
    drawerContent: null,
    drawerWidth: 250,
    showLogout: false,

    mini: false,
  }), // End of data

  methods: {}, // End of methods

  // -- Start HOOKS --

  watch: {},

  mounted() {
    this.drawerContent = this.drawerPublic;
  },

  activated() {},

  async beforeUpdate() {},

  updated() {},

  // --  End HOOKS --
};
</script>

<style scoped></style>
