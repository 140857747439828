<template>
  <div>
    <h1>Home</h1>
  </div>
</template>

<script>
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Home',

  computed: {}, // End of computed

  components: {}, // End of components

  data: () => ({
    tab: null,
    gHelperTitle: '',
    gHelperData: {},
  }), // End of data

  methods: {}, // End of methods

  async mounted() {},
};
</script>

<style scoped></style>
