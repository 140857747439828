<template>
  <v-card tile outlined class="mt-0 pt-0">
    <gStickerToolbar :gTitle="gTitle" :gAllowEdit="gAllowEdit"></gStickerToolbar>

    <!-- Sticker Text -->
    <v-card-text v-show="hasTextSlot" class="mb-0 pb-0 mt-0 pt-0">
      <slot name="gStickerText"></slot>
    </v-card-text>

    <!-- Sticker Actions -->
    <!--
    <v-card-actions v-show='hasActionsSlot'>
      <slot name='dsnEntityStickerActions'></slot>
    </v-card-actions>
    -->
  </v-card>
</template>

<script>
export default {
  name: 'gSticker',

  props: {
    gTitle: {
      type: String,
      required: false,
      default: () => 'Unknown Title in Sticker',
    },

    gAllowEdit: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  }, // End of props

  computed: {
    hasTextSlot() {
      return !!this.$slots['gStickerText'];
    },

    hasActionsSlot() {
      return !!this.$slots['gStickerActions'];
    },
  }, // end of Computed

  methods: {}, // End of Methods
}; // End of Export
</script>

<style scoped></style>
