<template>
  <v-card dense>
    <v-card-subtitle> this is the title </v-card-subtitle>
    <v-card-actions>
      <v-btn x-small color="green" @click="btnMarkers()">Markers Test</v-btn>
      <v-btn x-small color="green" @click="btnZoomBelgium()">Zoom Belgium</v-btn>
      <v-btn x-small color="green" @click="btnZoomFlanders()">Zoom Flanders</v-btn>
      <v-btn x-small color="green" @click="btnWhereAmI()">In Mijn buurt</v-btn>
      <v-btn x-small color="green" @click="btnZoomKenia()">Zoom Kenia</v-btn>

      <v-spacer></v-spacer>
      <span class="caption"> <v-checkbox v-model="geo" label="geo" class="caption mr-5"></v-checkbox></span>
      <span class="caption"> <v-checkbox v-model="searchForm" label="Search" class="caption mr-5"></v-checkbox></span>
      <span class="caption"> hoLngLat : {{ hoLngLat }}</span>
    </v-card-actions>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-toolbar dense floating class="map-toolbar" v-if="searchForm || geo">
            <v-form @submit.prevent="search" v-if="searchForm">
              <v-text-field
                hide-details
                prepend-icon="mdi-magnify"
                single-line
                v-model="query"
                placeholder="Zoek op gemeente"
              ></v-text-field>
            </v-form>

            <!--
            <v-btn icon v-if="geo" @click="requestLocation">
              <v-icon>mdi-crosshairs-gps</v-icon>
            </v-btn>
            -->
          </v-toolbar>
          <div :id="containerID" :style="containerCSS"></div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { gLog } from '@/services/GLogger';

import hoPlaces from '@/data/hoPlaces.json';

const mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
// Vuex Store
import { mapActions, mapGetters } from 'vuex';
export default {
  /*
  model: {
    prop: 'datetime',
    event: 'input',
  },
  */
  props: {
    value: {
      type: Object,
    },

    /*
      geo: {
        type: Boolean,
        default: true,
      },
      searchForm: {
        type: Boolean,
        default: true,
      },
      */
    height: {
      type: String,
      default: '500px',
    },
    initialLocation: {
      type: Array,
      default: () => [-0.496934, 51.437032],
    },
    color: {
      type: String,
      default: 'orange',
    },
    /*
    apiKey: {
      type: String,
      required: true,
    },
    */
    mapStyle: {
      type: String,
      default: 'mapbox://styles/mapbox/outdoors-v11',
      // style: 'mapbox://styles/mapbox/streets-v11',
    },
    containerID: {
      type: String,
      default: 'map',
    },
  },

  computed: {
    ...mapGetters('hoLayout', { mapboxApiKey: 'getMapboxApiKey' }),
    ...mapGetters('hoRegions', { regionFlanders: 'getRegionFlanders', regionBelgium: 'getRegionBelgium', regionKenia: 'getRegionKenia' }),

    containerCSS() {
      return {
        width: '100%',
        height: this.height,
      };
    },
  },

  data: () => ({
    map: null,
    query: '',
    location: [],

    geo: true,
    searchForm: true,

    hoLngLat: {},
  }), // End of data

  methods: {
    btnMarkers() {
      let center = [-77.04, 38.907];
      let zoom = 11.15;

      this.map.flyTo({ center, zoom });
    },

    btnWhereAmI() {
      // Request to get the user's current location
      window.navigator.geolocation.getCurrentPosition(position => {
        // get the latitude and longitude returned
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        // set location data
        this.setLocation({ lng, lat });
        this.map.flyTo({ center: [lng, lat], zoom: 15 });
      });
    },

    btnZoomKenia() {
      this.map.fitBounds(this.regionKenia);
    },

    btnZoomBelgium() {
      this.map.fitBounds(this.regionBelgium);
    },

    btnZoomFlanders() {
      this.map.fitBounds(this.regionFlanders);
    },

    async addSourceAsync(map, sourceId, data) {
      return new Promise((resolve, reject) => {
        /*
        map.addSource(sourceId, {
          type: 'geojson',
          data: data,
        });
        */
        map.addSource(sourceId, data);
        map.getSource(sourceId).once('data', data => {
          resolve(data);
        });
        map.getSource(sourceId).once('error', err => {
          reject(err);
        });
      });
    },

    async onLoadMap() {
      // this.map.addSource('places', hoPlaces);
      await this.addSourceAsync(this.map, 'places', hoPlaces);

      // Add a layer showing the places.
      this.map.addLayer({
        id: 'places',
        type: 'symbol',
        source: 'places',
        layout: {
          'icon-image': '{icon}-15',
          'icon-allow-overlap': true,
        },
      });

      // When a click event occurs on a feature in the places layer, open a popup at the
      // location of the feature, with description HTML from its properties.
      this.map.on('click', 'places', this.onClickPlaces);

      // Change the cursor to a pointer when the mouse is over the places layer.
      this.map.on('mouseenter', 'places', this.onMouseEnter);

      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'places', this.onMouseLeave);
    },

    onClickPlaces(e) {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.description;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(this.map);
    },

    onMouseEnter() {
      this.map.getCanvas().style.cursor = 'pointer';
    },

    onMouseLeave() {
      this.map.getCanvas().style.cursor = '';
    },

    initMap() {
      mapboxgl.accessToken = this.mapboxApiKey;
      // Create map object

      this.map = new mapboxgl.Map({
        container: this.containerID,
        style: this.mapStyle,

        // center: this.initialLocation,
        bounds: this.regionFlanders,
        zoom: 5,
      });

      this.map.on('load', this.onLoadMap);

      // Adds basic zoom and rotate control
      this.map.addControl(new mapboxgl.NavigationControl());

      // Add Click Listener

      this.map.on('click', e => {
        gLog('now in click Listener', e.lngLat);
        this.hoLngLat = e.lngLat;

        this.setLocation(e.lngLat);
      });
    },
    removeMapMarkers() {
      const oldMarker = document.querySelector('.mapboxgl-marker');
      if (oldMarker) {
        oldMarker.parentElement.removeChild(oldMarker);
      }
    },
    addMapMarker(lngLat) {
      new mapboxgl.Marker({ color: this.color }).setLngLat(lngLat).addTo(this.map);
    },
    setLocationCoordinates(lngLat) {
      this.location = [lngLat.lng, lngLat.lat];
    },
    requestLocation() {
      // Request to get the user's current location
      window.navigator.geolocation.getCurrentPosition(position => {
        // get the latitude and longitude returned
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        // set location data
        this.setLocation({ lng, lat });
        this.map.flyTo({ center: [lng, lat], zoom: 15 });
      });
    },
    setLocation(lngLat) {
      this.removeMapMarkers();
      this.addMapMarker(lngLat);
      this.setLocationCoordinates(lngLat);
      // this.$emit('input', this.location);
    },
    async search() {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.query}.json?access_token=${this.mapboxApiKey}`,
      );

      gLog('rrrrrrrrrrrrrrrrrrrrrrrr', response);
      this.query = '';
      const responseBody = await response.json();

      // Check we have at least 1 result
      if (responseBody.features.length == 0) {
        alert('no results found');
        return null;
      }

      const [lng, lat] = responseBody.features[0].center;
      this.setLocation({ lng, lat });
      this.map.flyTo({ center: [lng, lat], zoom: 15 });
    },
  },
  mounted() {
    this.initMap();
    gLog('hhhhhohoho', hoPlaces);
  },
};
</script>

<style>
.map-toolbar {
  margin-bottom: -60px;
  margin-left: 8px;
  z-index: 10;
}
</style>
