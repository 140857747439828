import { render, staticRenderFns } from "./VgAppTopbar.vue?vue&type=template&id=3cb51d5c&scoped=true&"
import script from "./VgAppTopbar.vue?vue&type=script&lang=js&"
export * from "./VgAppTopbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cb51d5c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
installComponents(component, {VSystemBar})
