import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
Vue.use(VueI18n);

const loadMessages = () => {
  let messages = {};
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      let ctx;
      const locale = key.split('/')[1];

      messages[locale] = { ...messages[locale], ...locales(key) };

      // Needed for Validations !!
      messages[locale]['fields'] = { ...messages[locale]['fields'], ...locales(key) };

      // Importing Validation Messages
      ctx = require(`vee-validate/dist/locale/${locale}.json`);
      messages[locale]['validation'] = ctx.messages;

      // Importing Vuetify Messages
      ctx = require(`vuetify/es5/locale/${locale}.js`);
      messages[locale]['$vuetify'] = ctx.default;
    }
  });
  return messages;
};

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },

    // fmtModifiedOn
    fmtModifiedOn: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
    // fmtSystembarDate
    fmtSystembarDate: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',

      hour12: false,
    },
  },
  nl: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },

    // fmtModifiedOn HAS TO BE Defined for NL
    // cause there is NO FALL BACK to English
    fmtModifiedOn: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
    // fmtSystembarDate
    fmtSystembarDate: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    },
  },
};

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadMessages(),
  dateTimeFormats, // set date and time formats
});
