// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {}, // End of props

  computed: {
    // getRegionNiscode

    ...mapGetters('vgRegion', { regionNiscode: 'getRegionNiscode' }),

    // Logo Width in Header
    widthLogo() {
      let retValue = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          // case 'sm':

          retValue = 70;
          break;
        default:
          retValue = 120;
          break;
      }
      return retValue;
    },
  }, // End of computed

  data: () => ({
    areaHeight: 'height: 83vh;',

    // Midwest Logo stuff
    srcLogoMidwest: require('@/assets/logos/midwest.png'), // 200
  }),

  methods: {
    isRegion(niscode) {
      return parseInt(niscode) == parseInt(this.regionNiscode);
    },

    dsnChangeRouter(name, params = {}) {
      let dirty = false;

      if (!dirty) {
        // Only allow routerChanges when form is NOT dirty
        this.$router.push({ name, params }).catch(err => {}); // avoids displaying an error...;
      } else {
      }

      return !dirty;
    },
  }, // End of Methods
}; // End of Export
