// vuex vgRegion

import { gLog } from '@/services/GLogger';
// Classes
import gSelector from '@/classes/clsSelector';

import {
  // postSearch,
  getPersonById,
  putPersonById,
  postPerson,
  postList,
} from '@/services/http/Person';

export const state = {
  _vgPerson: null,
  _vgPersons: [],
  _selector: new gSelector({ hasData: false, count: 0, _pageSize: 25, _CurrentPage: 0 }),
};

export const getters = {
  getVgPerson: state => state._vgPerson,
  getVgPersons: state => state._vgPersons,
  getSelector: state => state._selector,
};

export const mutations = {
  setPerson: (state, person) => (state._vgPerson = person),

  setPersons: (state, payload) => {
    let { Data, Pagination } = payload;
    state._selector.mutate(Data, Pagination);
    state._vgPersons.push(...Data);
  },
};

export const actions = {
  setPersonById: async ({ state, commit }, payload) => {
    let { id } = payload;
    let params = {};
    let person = await getPersonById(id, params);
    return commit('setPerson', person);
  },

  updatePersonById: async ({ state, commit }, payload) => {
    let { id, person } = payload;
    let params = {};
    let updatedPerson = await putPersonById(id, person);
    return commit('setPerson', updatedPerson);
  },

  savePerson: async ({ state, commit }, payload) => {
    // let user = await getCurrentLoggedInUser();
    // let params = {};
    let person = await postPerson(payload);
    gLog('person after save', person);
    // return commit('setPerson', person);
  },

  createPerson: async ({ state, commit }, payload) => {
    let person = await postPerson(payload);
    gLog('person after save', person);
    return commit('setPerson', person);
  },

  listPersons: async ({ dispatch, state, commit }, payload) => {
    try {
      let { FirstName, LastName } = payload;

      let Pagination = { Page: 1, PageSize: 25 };
      let params = { ...payload, Pagination };

      let res = await postList(params);
      return commit('setPersons', res);
    } catch (e) {
      console.log(e.message);
    }
  },

  setPersons: async ({ dispatch, state, commit }, payload) => {
    try {
      // let {} = payload;

      // let Pagination = { Page: state._selector._CurrentPage + 1, PageSize: state._selector._pageSize };
      let Pagination = { Page: 1, PageSize: 25 };
      let res = await postSearch({ Pagination });
      return commit('setPersons', res);
    } catch (e) {
      console.log(e.message);
    }
  },

  resetSelector: async ({ state, commit }, payload) => commit('resetSelector', payload),
};
