/**
 * Generated     With : Geoit.Swagger.Parser.Generator.Node
 *            Version : 0.3.30
 *                 On : 12/3/2021, 08:54:28
 * Parsed swaggerJSON
 *              Title : VersteendeGetuigenissen.API
 *           Location : https://vg-beta.geo-it.be/api/swagger/docs/v1
 *            Version : v1
 *           API Root : https://vg-beta.geo-it.be/api
 *
 * @summary Service  file to handle Api Endpont User
 *
 * @link                https://vg-beta.geo-it.be/api/swagger/docs/v1
 * @file                User.js.
 * @author              Johnny Driesen <johnny.driesen@geoit.be>.
 * @version             0.3.30
 * @since               x.y.z
 * @copyright           Geo-IT bv
 */

import { HTTP, getExtraHeaders } from './base';
import { gLog } from '@/services/GLogger';

// const END_POINT = 'User';

// ------------ GET ---------------------------------------------
/**
 * @function getUser
 */

// -- cntEndpoints: 117 .:. /User
export const getUser = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/User`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.get(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ GET ---------------------------------------------
/**
 * @function getUserById
 */

// -- cntEndpoints: 119 .:. /User/{id}
export const getUserById = async (id, params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/User/${id}`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.get(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ GET ---------------------------------------------
/**
 * @function getAllRoles
 */

// -- cntEndpoints: 122 .:. /User/AllRoles
export const getAllRoles = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/User/AllRoles`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.get(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ GET ---------------------------------------------
/**
 * @function getCurrentLoggedInUser
 */

// -- cntEndpoints: 123 .:. /User/CurrentLoggedInUser
export const getCurrentLoggedInUser = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/User/CurrentLoggedInUser`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.get(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ GET ---------------------------------------------
/**
 * @function getGemeenteBeheerders
 */

// -- cntEndpoints: 125 .:. /User/GemeenteBeheerders
export const getGemeenteBeheerders = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/User/GemeenteBeheerders`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.get(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ GET ---------------------------------------------
/**
 * @function getNiscodeForAdminMunicipalty
 */

// -- cntEndpoints: 132 .:. /User/NiscodeForAdminMunicipalty
export const getNiscodeForAdminMunicipalty = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/User/NiscodeForAdminMunicipalty`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.get(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ GET ---------------------------------------------
/**
 * @function getRolesForCemeteries
 */

// -- cntEndpoints: 135 .:. /User/RolesForCemeteries
export const getRolesForCemeteries = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/User/RolesForCemeteries`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.get(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ GET ---------------------------------------------
/**
 * @function getUserWithSameRights
 */

// -- cntEndpoints: 138 .:. /User/UserWithSameRights
export const getUserWithSameRights = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/User/UserWithSameRights`;

    const fixedParams = {};

    params = { ...fixedParams, ...params };
    const response = await HTTP.get(endpoint, { headers, params });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postUser
 */

// -- cntEndpoints: 118 .:. /User
export const postUser = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { UserName,Email,Name,FirstName,Organisation,Telephone,IsApproved,Comment } = params ;  
		*/

    const endpoint = `/User`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    gLog('rrrrrrrrrrrrrrr', response);

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postAddUserToRole
 */

// -- cntEndpoints: 121 .:. /User/AddUserToRole
export const postAddUserToRole = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { UserName,Role } = params ;  
		*/

    const endpoint = `/User/AddUserToRole`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postExpirationDateCookie
 */

// -- cntEndpoints: 124 .:. /User/ExpirationDateCookie
export const postExpirationDateCookie = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { UserName,Days } = params ;  
		*/

    const endpoint = `/User/ExpirationDateCookie`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postIsAdmin
 */

// -- cntEndpoints: 126 .:. /User/IsAdmin
export const postIsAdmin = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/User/IsAdmin`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postIsUserAdminForMunicipalty
 */

// -- cntEndpoints: 127 .:. /User/IsUserAdminForMunicipalty
export const postIsUserAdminForMunicipalty = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { UserName,NisCode } = params ;  
		*/

    const endpoint = `/User/IsUserAdminForMunicipalty`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postIsUserInRole
 */

// -- cntEndpoints: 128 .:. /User/IsUserInRole
export const postIsUserInRole = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { UserName,Role } = params ;  
		*/

    const endpoint = `/User/IsUserInRole`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postIsUserInRoleForCemetery
 */

// -- cntEndpoints: 129 .:. /User/IsUserInRoleForCemetery
export const postIsUserInRoleForCemetery = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { UserName,Role,NisCode,CemeteryId } = params ;  
		*/

    const endpoint = `/User/IsUserInRoleForCemetery`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postLogin
 */

// -- cntEndpoints: 130 .:. /User/Login
export const postLogin = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { UserName,Password } = params ;  
		*/

    const endpoint = `/User/Login`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postLogout
 */

// -- cntEndpoints: 131 .:. /User/Logout
export const postLogout = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const {  } = params ;  
		*/

    const endpoint = `/User/Logout`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postRemoveUserFromRole
 */

// -- cntEndpoints: 133 .:. /User/RemoveUserFromRole
export const postRemoveUserFromRole = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { UserName,Role } = params ;  
		*/

    const endpoint = `/User/RemoveUserFromRole`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postRemoveUserFromRoleWithCemeteries
 */

// -- cntEndpoints: 134 .:. /User/RemoveUserFromRoleWithCemeteries
export const postRemoveUserFromRoleWithCemeteries = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { UserName,Role,Roles } = params ;  
		*/

    const endpoint = `/User/RemoveUserFromRoleWithCemeteries`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postUserAdminForMunicipalty
 */

// -- cntEndpoints: 136 .:. /User/UserAdminForMunicipalty
export const postUserAdminForMunicipalty = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { UserName,NisCode } = params ;  
		*/

    const endpoint = `/User/UserAdminForMunicipalty`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postUserToRoleWithCemetery
 */

// -- cntEndpoints: 137 .:. /User/UserToRoleWithCemetery
export const postUserToRoleWithCemetery = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { UserName,Role,Roles } = params ;  
		*/

    const endpoint = `/User/UserToRoleWithCemetery`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ POST ---------------------------------------------
/**
 * @function postList
 */

// -- cntEndpoints: 139 .:. /User/list
export const postList = async (params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { UserName,Pagination } = params ;  
		*/

    const endpoint = `/User/list`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.post(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};

// ------------ PUT ---------------------------------------------
/**
 * @function putUserById
 */

// -- cntEndpoints: 120 .:. /User/{id}
export const putUserById = async (id, params = {}) => {
  try {
    const headers = getExtraHeaders();

    /*
		const { Email,Name,FirstName,Organisation,Telephone,IsApproved,Comment } = params ;  
		*/

    const endpoint = `/User/${id}`;

    const fixedParams = {};

    const data = { ...fixedParams, ...params };
    const response = await HTTP.put(endpoint, data, { headers });

    return response.data;
  } catch (error) {
    console.error('error', error);
  }
};
