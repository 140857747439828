<template>
  <v-card>
    <v-card-title>{{ gTitle }}</v-card-title>
    <v-card-subtitle>{{ `Length: (${gDataLength})` }}</v-card-subtitle>
    <v-card-text
      ><pre>{{ gData }}</pre></v-card-text
    >
  </v-card>
</template>

<script>
// Services
import { gLog } from '@/services/GLogger';
export default {
  name: 'GHelperJson',

  props: {
    gData: {
      type: [Array, Object],
      required: false,
      default: () => [],
    },

    gTitle: {
      type: String,
      required: false,
      default: () => 'Unknown Title',
    },
  }, // End of props

  computed: {
    gDataLength() {
      return Array.isArray(this.gData) ? this.gData.length : 0;
    },
  }, // End of computed

  components: {},

  data: () => ({}), // End of Data

  methods: {}, // End of Methods

  // **** Start HOOKS ****

  beforeCreate() {}, // End of created

  created() {}, // End of created

  beforeMount() {}, // End of created

  mounted() {}, // End of mounted

  beforeUpdate() {}, // End of created

  updated() {}, // End of updated

  activated() {}, // End of activated

  deactivated() {}, // End of activated

  beforeDestroy() {}, // End of beforeDestroy

  destroyed() {}, // End of beforeDestroy

  // **** End HOOKS ****
};
// End of Export
</script>

<style scoped></style>
