import { gLog } from '@/services/GLogger';
import moment from 'moment';

export const getFicheMetaArchitecture = (fiche, meta) => {
  let { Architecture = {} } = fiche;
  let {
    Designer: designerName,
    DesignerId: designerId,
    DesignerSigned: designerSigned,

    Executant: executantName,
    ExecutantId: executantId,
    ExecutantSigned: executantSigned,

    ExtraExecutant: extraExecutantName,
    ExtraExecutantId: extraExecutantId,
    ExtraExecutantSigned: extraExecutantSigned,
  } = Architecture;

  let { ArchitectureTypes, ArchitectureMaterials, ArchitectureStyles } = meta;

  let vgArchitecture = {
    vgDesigner: { id: designerId, name: designerName, signed: designerSigned },
    vgExecutant: { id: executantId, name: executantName, signed: executantSigned },
    vgExtraExecutant: { id: extraExecutantId, name: extraExecutantName, signed: extraExecutantSigned },

    vgTypes: ArchitectureTypes.filter(el => Architecture.Types.includes(el.id)),
    vgMaterials: ArchitectureMaterials.filter(el => Architecture.Materials.includes(el.id)),
    vgStyles: ArchitectureStyles.filter(el => Architecture.Styles.includes(el.id)),
  };
  return vgArchitecture;
};

export const getFicheMetaCriteria = (fiche, meta) => {
  let { Criteria = [] } = fiche;
  return Criteria;
};

export const getFicheMetaConcession = (fiche, meta) => {
  let { Concession = [] } = fiche;
  return Concession;
};

export const getFicheMetaPhotos = (fiche, meta) => {
  let { Photos = [] } = fiche;
  return Photos;
};

export const getFicheDocuments = (fiche, meta) => {
  let { Documents = [] } = fiche;
  return Documents;
};

export const getFicheBibliography = (fiche, meta) => {
  let { Bibliography = {} } = fiche;
  return Bibliography;
};

export const getFicheEpigraphy = (fiche, meta) => {
  let { Epigraphy = {} } = fiche;

  // Pick up the Heraldry
  let {
    HeraldryWeaponry: Weaponry = '',
    HeraldryWeaponryText: WeaponryText = '',
    HeraldryWeaponBearers: WeaponBearers = '',
    HeraldryWeaponBearersText: WeaponBearersText = '',
    HeraldryShieldCover: ShieldCover = '',
    HeraldryShieldCoverText: ShieldCoverText = '',
    HeraldryCharacterise: Characterise = '',
    HeraldryCharacteriseText: CharacteriseText = '',
    HeraldryDevise: Devise = '',
  } = Epigraphy;

  let vgHeraldry = {
    Weaponry,
    WeaponryText,
    WeaponBearers,
    WeaponBearersText,
    ShieldCover,
    ShieldCoverText,
    Characterise,
    CharacteriseText,
    Devise,
  };

  let { Photographer: photographerName, PhotographerId: photographerId, PhotographerSigned: photographerSigned } = Epigraphy;
  let vgPhotoGrapher = { id: photographerId, name: photographerName, signed: photographerSigned };

  let {
    PhotosMen: Men,
    PhotosWomen: Women,
    PhotosChildren: Children,
    PhotosCouple: Couple,
    PhotosPorcelain: Porcelain,
    Photoengravings: Engravings,
  } = Epigraphy;

  let vgPhotos = { Men, Women, Children, Couple, Porcelain, Engravings };

  let { Parts: vgParts } = Epigraphy;

  // Compose and Return the Object
  return { vgHeraldry, vgPhotoGrapher, vgPhotos, vgParts };
};

export const getFicheIconography = (fiche, meta) => {
  let { Iconography = {} } = fiche;
  let { IconographyVoorwerp, IconographyVegetatief, IconographyDierlijk, IconographyLettersymbolen, IconographyBeroepsgebonden } = meta;

  let vgIconography = {
    Voorwerp: {
      items: IconographyVoorwerp.filter(el => Iconography.voorwerp.includes(el.id)),
      opmerking: Iconography.voorwerp_opmerking,
    },

    Vegetatief: {
      items: IconographyVegetatief.filter(el => Iconography.vegetatief.includes(el.id)),
      opmerking: Iconography.vegetatief_opmerking,
    },

    Dierlijk: {
      items: IconographyDierlijk.filter(el => Iconography.dierlijk.includes(el.id)),
      opmerking: Iconography.dierlijk_opmerking,
    },

    Lettersymbolen: {
      items: IconographyLettersymbolen.filter(el => Iconography.lettersymbolen.includes(el.id)),
      opmerking: Iconography.lettersymbolen_opmerking,
    },

    Beroepsgebonden: {
      items: IconographyBeroepsgebonden.filter(el => Iconography.beroepsgebonden.includes(el.id)),
      opmerking: Iconography.beroepsgebonden_opmerking,
    },
  };
  return vgIconography;
};

export const getFicheSculpture = (fiche, meta) => {
  let { Sculpture = {} } = fiche;

  let {
    FreeDesigner: freeDesignerName,
    FreeDesignerId: freeDesignerId,
    FreeDesignerSigned: freeDesignerSigned,

    FreeExecutant: freeExecutantName,
    FreeExecutantId: freeExecutantId,
    FreeExecutantSigned: freeExecutantSigned,

    FreeSubject,

    // OK
    EmbeddedDesigner: embeddedDesignerName,
    EmbeddedDesignerId: embeddedDesignerId,
    EmbeddedDesignerSigned: embeddedDesignerSigned,

    // OK
    EmbeddedExecutant: embeddedExecutantName,
    EmbeddedExecutantId: embeddedExecutantId,
    EmbeddedExecutantSigned: embeddedExecutantSigned,

    EmbeddedSubject,

    SmallHooks,
    SmallRemark,
  } = Sculpture;

  let {
    // Embedded
    SculptureEmbeddedDescriptions,
    SculptureEmbeddedMaterials,

    // Small
    SculptureSmallDescriptions,
    SculptureSmallMaterials,

    // Free
    SculptureFreeDescriptions,
    SculptureFreeMaterials,
  } = meta;

  let vgSculpture = {
    Embedded: {
      Subject: EmbeddedSubject,

      Descriptions: SculptureEmbeddedDescriptions.filter(el => Sculpture.EmbeddedDescriptions.includes(el.id)),
      Materials: SculptureEmbeddedMaterials.filter(el => Sculpture.EmbeddedMaterials.includes(el.id)),

      Designer: { id: embeddedDesignerId, name: embeddedDesignerName, signed: embeddedDesignerSigned },
      Executant: { id: embeddedExecutantId, name: embeddedExecutantName, signed: embeddedExecutantSigned },
    },

    Small: {
      Descriptions: SculptureSmallDescriptions.filter(el => Sculpture.SmallDescriptions.includes(el.id)),
      Materials: SculptureSmallMaterials.filter(el => Sculpture.SmallMaterials.includes(el.id)),

      Hooks: SmallHooks,
      Remark: SmallRemark,
    },

    Free: {
      Subject: FreeSubject,
      Descriptions: SculptureFreeDescriptions.filter(el => Sculpture.FreeDescriptions.includes(el.id)),
      Materials: SculptureFreeMaterials.filter(el => Sculpture.FreeMaterials.includes(el.id)),

      Designer: { id: freeDesignerId, name: freeDesignerName, signed: freeDesignerSigned },
      Executant: { id: freeExecutantId, name: freeExecutantName, signed: freeExecutantSigned },
    },
  };
  return vgSculpture;
};
