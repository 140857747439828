<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-card-subtitle class="caption"
          >Registreer jouw Horeca zaak</v-card-subtitle
        >
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="select"
                :hint="setHint"
                :items="items"
                item-text="state"
                item-value="abbr"
                label="Uw postcode"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="name"
                hint="Naam van jouw zaak"
                label="Naam"
                persistent-hint
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="address"
                hint="Straat en Nummer"
                label="Adres"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn x-small text @click="btnCancel()">anulleren</v-btn>
          <v-btn x-small color="green" @click="btnSave()">Bewaren</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { gLog } from "@/services/GLogger";

// Vuex Store
import { mapActions, mapGetters } from "vuex";

export default {
  name: "vwHoRegister",

  computed: {
    setHint() {
      return "state" in this.select
        ? `${this.select.state}, ${this.select.abbr}`
        : "";
    },
  }, // End of computed

  components: {}, // End of components

  data: () => ({
    // snackbar

    multiLine: true,
    snackbar: false,
    text: `I'm a multi-line snackbar.`,

    name: "",
    address: "",

    select: {},

    // select: { state: "Florida", abbr: "FL" },
    items: [
      { state: "Florida", abbr: "FL" },
      { state: "Georgia", abbr: "GA" },
      { state: "Nebraska", abbr: "NE" },
      { state: "California", abbr: "CA" },
      { state: "New York", abbr: "NY" },
    ],
  }), // End of data

  methods: {
    btnSave() {
      gLog("Gegevens worden bewaard");
      this.snackbar = true;
    },

    btnCancel() {
      gLog("Gegevens worden NIET bewaard");
    },
  }, // End of methods

  async mounted() {
    this.select = {};
    this.name = "";
    this.address = "";
  },
};
</script>

<style scoped></style>
