// vuex hoLayout

import { gLog } from '@/services/GLogger';

const ACTION_SEARCH = 1;
const ACTION_REGISTER = 2;

export const state = {
  _drawerRight: false,
  _action: ACTION_SEARCH,
  _mapboxApiKey: process.env.VUE_APP_MAPBOX_KEY,
};

export const getters = {
  getDrawerRight: state => state._drawerRight,
  getAction: state => state._action,
  getMapboxApiKey: state => state._mapboxApiKey,
};

export const mutations = {
  setDrawerRight: (state, payload) => (state._drawerRight = !state._drawerRight),

  setAction: (state, action) => (state._action = action),
};

export const actions = {
  setDrawerRight: async ({ commit }, payload) => commit('setDrawerRight', null),

  setAction: async ({ commit }, action) => commit('setAction', action),
};
