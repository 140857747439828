<template>
  <span class="text-body-2 font-weight-regular font-italic">{{ vgText }}</span>
</template>

<script>
export default {
  name: 'vgFicheItemParagraph',

  props: {
    vgText: {
      type: String,
      required: false,
      default: () => '',
    },
  }, // End of props

  computed: {}, // end of Computed

  methods: {}, // End of Methods
}; // End of Export
</script>

<style scoped></style>
