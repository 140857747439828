<template>
  <v-toolbar dense class="mt-0 pt-0 mb-2">
    <v-toolbar-title>{{ vgTitle }}</v-toolbar-title>
  </v-toolbar>
</template>

<script>
// Services
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'vgCrudToolbar',

  props: {
    vgTitle: {
      type: String,
      required: true,
      default: () => 'Unknown Crud Toolbar Title',
    },
  }, // End of props

  computed: {}, // End of computed

  methods: {}, // End of methods

  // ---- Start HOOKS

  watch: {}, // End of watch

  async mounted() {},
  // ---- End HOOKS
};
</script>
