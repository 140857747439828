<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-btn x-small class="mr-2" color="blue" @click="btnGetDeceaseds()">Get Deceaseds</v-btn>
      <v-btn x-small class="mr-2" color="blue" @click="btnGetCoDeceasedsByLocation()">Get CoDeceasedsByLocation</v-btn>
      <v-spacer></v-spacer>
    </v-toolbar>

    <hr />
    <GHelperJson :gTitle="gHelperTitle" :gData="gHelperData" />
  </v-container>
</template>

<script>
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TabDeceased',

  computed: {
    ...mapGetters('vgDeceased', {
      vgDeceaseds: 'getVgDeceaseds',
      vgCoDeceaseds: 'getVgCoDeceaseds',
    }),
  }, // End of computed

  components: {}, // End of components

  data: () => ({
    gHelperTitle: '',
    gHelperData: {},
  }), // End of data

  methods: {
    // ...mapActions('vgUser', ['setUsers', 'setUser']),
    ...mapActions('vgDeceased', ['setVgDeceaseds', 'setVgCoDeceaseds']),

    async btnGetDeceaseds() {
      // let oSearch = { Niscodes, FirstName, LastName, FreeSearchTerm, Locations, GrafFicheNr, Includes };

      let oSearch = {
        GrafFicheNr: 65,
        Niscodes: ['36007'],
        LastName: '',
        FirstName: 'a',
        FreeSearchTerm: '',
        Locations: [],

        Includes: { bibliography: false },
      };

      await this.setVgDeceaseds(oSearch);
      this.gHelperTitle = 'Got gDeceaseds';
      this.gHelperData = this.vgDeceaseds;
    },

    async btnGetCoDeceasedsByLocation() {
      // let Locations = [{ Niscode: '36007', Code: 'GB', Block: 'A', Row: '13', Nr: '3' }];
      let Locations = [{ Niscode: '36007', Code: 'GB', Block: 'N', Row: '3', Nr: '8' }];

      let oSearch = {
        // GrafFicheNr: 65,
        Niscodes: [],
        LastName: '',
        FirstName: '',
        FreeSearchTerm: '',
        Locations,
        // Includes: { bibliography: false },
      };

      await this.setVgCoDeceaseds(oSearch);
      this.gHelperTitle = 'Got gDeceaseds by Location';
      this.gHelperData = this.vgCoDeceaseds;
    },
  }, // End of methods

  // ----- Start Hooks
  watch: {}, // End of watch

  async mounted() {},
};
</script>

<style scoped></style>
