<template>
  <v-main>
    <!--    <v-container fluid>      -->
    <v-container fluid class="fill-height">
      <v-sheet outlined :color="outerContainerColor" width="100%" class="fill-height" ref="outerContainer" v-resize="onResize">
        <v-card flat class="fill-height">
          <!-- this is the Main Container with the content -->
          <!-- If using vue-router, use the line below -->
          <router-view :key="$route.fullPath" :dsnParentRefs="$refs" />
        </v-card>
      </v-sheet>
    </v-container>
    <!--    </v-container>    -->
  </v-main>
</template>

<script>
// Services
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'VgAppContent',

  computed: {
    ...mapGetters('layout', { layoutTimeout: 'getLayoutTimeout' }),

    // use red when in development ... more easy to see the container :)
    outerContainerColor() {
      return 'primary';
    },
  }, // End of computed

  components: {}, // End of components

  data: () => ({}), // End of data

  methods: {
    ...mapActions('layout', ['setHeightOuterContainer']),

    async onResize() {
      // onResize is also executed on initial startup ...
      // let height = this.$refs.outerContainer.$el.clientHeight;
      await this.$nextTick();
      setTimeout(() => {
        this.setHeightOuterContainer(this.$refs.outerContainer.$el.clientHeight);
      }, this.layoutTimeout);
    },
  }, // End of methods

  // -- Start HOOKS --
  watch: {},

  mounted() {},

  async activated() {},

  async beforeUpdate() {},

  async updated() {},
  // --  End HOOKS --
};
</script>

<style scoped></style>
