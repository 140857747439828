// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.

import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

import { gLog } from '@/services/GLogger';

// ********************************************

const requireComponent = require.context(
  // The relative path of the components folder
  '@/components',
  // Whether or not to look in subfolders
  true,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue)$/,
);

// Used in vue.use...
const install = (Vue, options = {}) => {
  requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(
      camelCase(
        // Gets the file name regardless of folder depth
        fileName
          .split('/')
          .pop()
          .replace(/\.\w+$/, ''),
      ),
    );

    // gLog('Registered Component:', componentName);

    // Register component(s) globally

    Vue.component(
      componentName,
      // Look for the component options on `.default`, which will
      // exist if the component was exported with `export default`,
      // otherwise fall back to module's root.
      componentConfig.default || componentConfig,
    );
  });
};

// auto install
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default { install };
