// vuex vgFiche

// Services
import { gLog } from '@/services/GLogger';
// Classes
import gSelector from '@/classes/clsSelector';

import {
  getFicheMetaArchitecture,
  getFicheMetaCriteria,
  getFicheMetaConcession,
  getFicheMetaPhotos,
  getFicheDocuments,
  getFicheBibliography,
  getFicheEpigraphy,
  getFicheIconography,
  getFicheSculpture,
} from '@/services/vgMetaFiche';

// Importing Fiche Stuff
import { getGraveSheetById, postList } from '@/services/http/GraveSheet';
import { getArchitectureTypes } from '@/services/http/ArchitectureTypes';
import { getArchitectureStyles } from '@/services/http/ArchitectureStyles';
import { getSculptureEmbeddedDescriptions } from '@/services/http/SculptureEmbeddedDescriptions';
import { getSculptureSmallDescriptions } from '@/services/http/SculptureSmallDescriptions';
import { getSculptureFreeDescriptions } from '@/services/http/SculptureFreeDescriptions';
import { getIconographyDescriptions } from '@/services/http/IconographyDescriptions';
import { getSculptureMaterials, getSculptureSmallMaterials, getArchitectureMaterials } from '@/services/http/Material';
import { getLanguages } from '@/services/http/Languages';
import { getScripts } from '@/services/http/Scripts';
import { getGraphical } from '@/services/http/Graphical';
import { getScales } from '@/services/http/Scales';
import { getConservations } from '@/services/http/Conservations';

export const state = {
  _fiche: {},
  _fiches: [],

  _vgFiche: {},
  _graffiche: {},
  _grafficheMetaData: null,
  _selector: new gSelector({ hasData: false, count: 0, _pageSize: 25, _CurrentPage: 0 }),
};

export const getters = {
  getFiche: state => state._fiche,

  // returning the backend Fiches
  getFiches: state => state._fiches,

  getVgFiche: state => state._vgFiche,
  getGraffiche: state => state._graffiche,
  getSelector: state => state._selector,
};

export const mutations = {
  setVgFiche: (state, fiche) => {
    let { _ficheMetaData: meta } = state;

    state._fiche = fiche;

    let { Name, InventoryNumber, Location } = fiche;
    let { Block, Row, Number: Nr } = Location;

    let vgFicheId = InventoryNumber;
    let vgName = Name;

    let vgLocation = { Block, Row, Nr };

    let vgArchitecture = getFicheMetaArchitecture(fiche, meta);
    let vgCriteria = getFicheMetaCriteria(fiche, meta);
    let vgConcession = getFicheMetaConcession(fiche, meta);
    let vgPhotos = getFicheMetaPhotos(fiche, meta);
    let vgDocuments = getFicheDocuments(fiche, meta);
    let vgBibliography = getFicheBibliography(fiche, meta);
    let vgEpigraphy = getFicheEpigraphy(fiche, meta);
    let vgIconography = getFicheIconography(fiche, meta);
    let vgSculpture = getFicheSculpture(fiche, meta);

    state._vgFiche = {
      vgFicheId,
      vgName,
      vgLocation,
      vgEpigraphy,
      vgArchitecture,
      vgCriteria,
      vgConcession,
      vgPhotos,
      vgDocuments,
      vgBibliography,
      vgIconography,
      vgSculpture,

      // ...fiche,
    };
  },

  setFiches: (state, payload) => {
    // Setting the fiches for the backend
    let { Data, Pagination } = payload;
    state._selector.mutate(Data, Pagination);
    state._fiches.push(...Data);
  },

  setFiche: (state, fiche) => (state._fiche = fiche),
};

export const actions = {
  _getMetaDataFiche: async ({ state }, payload) => {
    return {
      // General stuff ...
      // Languages: await getLanguages(),
      // Scripts: await getScripts(),
      // Graphical: await getGraphical(),
      // Scales: await getScales(),
      // Conservations: await getConservations(),

      // Location: await postDetails(soPostDetails),
      ArchitectureTypes: await getArchitectureTypes(),
      ArchitectureMaterials: await getArchitectureMaterials(),
      ArchitectureStyles: await getArchitectureStyles(),

      // Ingewerkt Sculptuur
      SculptureEmbeddedDescriptions: await getSculptureEmbeddedDescriptions(),
      SculptureEmbeddedMaterials: await getSculptureMaterials(),

      // Klein Sculptuur
      SculptureSmallDescriptions: await getSculptureSmallDescriptions(),
      SculptureSmallMaterials: await getSculptureSmallMaterials(),

      // Free Sculptuur
      SculptureFreeDescriptions: await getSculptureFreeDescriptions(),
      SculptureFreeMaterials: await getSculptureMaterials(),

      // Iconography
      IconographyVoorwerp: await getIconographyDescriptions({ type: 'voorwerp' }),
      IconographyVegetatief: await getIconographyDescriptions({ type: 'vegetatief' }),
      IconographyDierlijk: await getIconographyDescriptions({ type: 'dierlijk' }),
      IconographyLettersymbolen: await getIconographyDescriptions({ type: 'lettersymbolen' }),
      IconographyBeroepsgebonden: await getIconographyDescriptions({ type: 'beroepsgebonden' }),
    };
    // }
  },

  setVgFiche: async ({ dispatch, state, commit, rootGetters, getters, rootState }, payload) => {
    try {
      let { id, params } = payload;

      // Let's fill the metaData for fiches (in case we don't have it yet)
      // And attach it to the state ...Will be done only ONCE per session...
      state._ficheMetaData = !state._ficheMetaData ? await dispatch('_getMetaDataFiche', {}) : state._ficheMetaData;

      let fiche = id != 0 && id != null ? await getGraveSheetById(id, params) : {};
      gLog('found fiche', fiche);

      return commit('setVgFiche', fiche);
    } catch (e) {
      console.log(e.message);
    }
  },

  setFicheById: async ({ dispatch, state, commit, rootGetters, getters, rootState }, payload) => {
    try {
      let { id, params } = payload;

      // Let's fill the metaData for fiches (in case we don't have it yet)
      // And attach it to the state ...Will be done only ONCE per session...
      state._ficheMetaData = !state._ficheMetaData ? await dispatch('_getMetaDataFiche', {}) : state._ficheMetaData;

      let fiche = id != 0 && id != null ? await getGraveSheetById(id, params) : {};
      gLog('found fiche', fiche);

      return commit('setVgFiche', fiche);
    } catch (e) {
      console.log(e.message);
    }
  },

  searchFiches: async ({ dispatch, state, commit, rootGetters, getters, rootState }, payload) => {
    // Searching Fiches for the backend
    try {
      // let { id, params } = payload;

      let Pagination = { Page: 1, PageSize: 25 };

      let res = await postList({ Pagination });

      gLog('found fiches', res);

      return commit('setFiches', res);
    } catch (e) {
      console.log(e.message);
    }
  },

  resetSelector: async ({ state, commit }, payload) => commit('resetSelector', payload),
};
