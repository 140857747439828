// vuex vgDeceased

const uniqid = require('uniqid');

// Classes
import gSelector from '@/classes/clsSelector';

// Services
import { gLog } from '@/services/GLogger';

// HTTP Services
// Importing the services
import { postList } from '@/services/http/DeceasedPersons';
export const state = {
  _vgDeceased: {},
  _vgDeceaseds: [],
  _vgCoDeceaseds: [],
  _selector: new gSelector({ hasData: false, count: 0, _pageSize: 25, _CurrentPage: 0 }),
};

export const getters = {
  getVgDeceased: state => state._vgDeceased,
  getVgDeceaseds: state => state._vgDeceaseds,
  getVgCoDeceaseds: state => state._vgCoDeceaseds,
  getSelector: state => state._selector,
};

export const mutations = {
  setVgDeceased: (state, vgDeceased) => (state._vgDeceased = vgDeceased),

  setVgCoDeceaseds: (state, payload) => {
    let { Data, Pagination } = payload;

    // Create an internal vgId per coDeceased...
    // makes our life far more easy ... (will be used in tabDeceaseds...)
    Data.forEach(rec => (rec['vgId'] = uniqid('vg-')));
    state._vgCoDeceaseds = Data;
  },

  setVgDeceaseds: (state, payload) => {
    let { Data, Pagination } = payload;
    // state._vgDeceaseds = Data;

    state._selector.mutate(Data, Pagination);
    state._vgDeceaseds.push(...Data);
  },

  resetVgDeceaseds: (state, payload) => {
    state._selector._CurrentPage = 0;
    state._selector.count = 0;
    state._selector.infiniteId += 1;

    state._vgDeceaseds = [];
    state._vgDeceased = {};
  },

  resetSelector: (state, payload) => {
    state._selector._CurrentPage = 0;
    state._selector.count = 0;
    state._selector.infiniteId += 1;

    state._vgDeceaseds = [];
    state._vgDeceased = {};
  },
};

export const actions = {
  setVgCoDeceaseds: async ({ dispatch, state, commit }, payload) => {
    try {
      let Niscodes = [];
      let LastName = '';
      let FirstName = '';
      let FreeSearchTerm = '';
      // let Locations = [{ Niscode: '36007', Code: 'GB', Block: 'A', Row: '13', Nr: '3' }];
      let { GrafFicheNr, Locations } = payload;

      // let { niscodes: Niscodes, lastname: LastName, firstname: FirstName, freeterm: FreeSearchTerm } = payload;
      let Pagination = { Page: 1, PageSize: 30 }; // Ever more than 30 ????
      let Includes = { bibliography: true };

      // Niscodes = Array.isArray(Niscodes) ? Niscodes : Niscodes.split();

      // Special suituation for backend...
      // In case we search for CoDeceaseds...
      // and we have a GrafFicheNr AND Locations...
      // We have to give priority to the GrafFicheNr

      if (GrafFicheNr) Locations = [];

      let res = await postList({
        GrafFicheNr,
        Niscodes,
        LastName,
        FirstName,
        FreeSearchTerm,
        Includes,
        Locations,
        Pagination,
      });

      return commit('setVgCoDeceaseds', res);
    } catch (e) {
      console.log(e.message);
    }
  },

  setVgDeceaseds: async ({ dispatch, state, commit }, payload) => {
    try {
      let { Niscodes, FirstName, LastName, FreeSearchTerm, Locations, GrafFicheNr } = payload;

      // let Pagination = { Page: state._selector._CurrentPage + 1, PageSize: state._selector._pageSize };
      let Pagination = { Page: 1, PageSize: 25 };
      let Includes = { bibliography: true };
      let params = { ...payload, Includes, Pagination };

      let res = await postList(params);

      // let Niscodes = ['36007'];
      // let LastName = 'Po';
      // let FirstName = '';
      // let Pagination= { Page: 1, PageSize: 25 }

      // let Locations = [];

      // Niscodes = Array.isArray(Niscodes) ? Niscodes : Niscodes.split();

      // let GrafFicheNr = null;
      /*
      let res = await postSearchDeceasedPersons({
        GrafFicheNr,
        Niscodes,
        LastName,
        FirstName,
        FreeSearchTerm,
        Includes,
        Locations,
        Pagination,
      });
      */

      return commit('setVgDeceaseds', res);
    } catch (e) {
      console.log(e.message);
    }
  },

  setVgDeceased: async ({ dispatch, state, commit }, vgDeceased) => {
    try {
      return commit('setVgDeceased', vgDeceased);
    } catch (e) {
      console.log(e.message);
    }
  },

  resetVgDeceaseds: async ({ dispatch, state, commit }, payload) => {
    try {
      return commit('resetVgDeceaseds', {});
    } catch (e) {
      console.log(e.message);
    }
  },

  resetSelector: async ({ state, commit }, payload) => commit('resetSelector', payload),
};
