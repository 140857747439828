<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-btn x-small class="mr-2" color="green" @click="btnLogin()">Login</v-btn>
      <v-btn x-small class="mr-2" color="red" @click="btnLogout()">Logout</v-btn>
      <v-btn x-small class="mr-2" color="blue" @click="btnGetById(96)">GetById</v-btn>
      <v-btn x-small class="mr-2" color="orange" @click="btnPutById(97)">PutById</v-btn>

      <v-btn x-small class="mr-2" color="green" @click="btnPost()">Post (create new)</v-btn>

      <v-spacer></v-spacer>

      <!--
      <v-btn icon>
        <v-icon color="green">mdi-lock-open-variant-outline</v-icon>
      </v-btn>
      -->
      <v-btn icon>
        <v-icon :color="lockColor">{{ lockIcon }}</v-icon>
      </v-btn>
    </v-toolbar>

    <!--
    
    -->
    <hr />
    <GHelperJson :gTitle="gHelperTitle" :gData="gHelperData" />
  </v-container>
</template>

<script>
import { gLog } from '@/services/GLogger';

// Vuex Store
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TabUser',

  computed: {
    ...mapGetters('vgUser', {
      vgUser: 'getVgUser',
      vgUsers: 'getVgUsers',
      vgUserIsLoggedIn: 'getVgUserIsLoggedIn',
      vgUserLoggedIn: 'getVgUserLoggedIn',
    }),

    lockColor() {
      return this.vgUserIsLoggedIn ? 'green' : 'red';
    },

    lockIcon() {
      return this.vgUserIsLoggedIn ? 'mdi-lock-open-variant-outline' : 'mdi-lock-outline';
    },
  }, // End of computed

  components: {}, // End of components

  data: () => ({
    gHelperTitle: '',
    gHelperData: {},

    // Form Data ...
    UserName: '',
    Password: '',
  }), // End of data

  methods: {
    // ...mapActions('vgUser', ['setUsers', 'setUser']),
    ...mapActions('vgUser', ['loginUser', 'logoutUser', 'setUserLoggedIn', 'setUserById', 'updateUserById', 'createUser']),

    async btnGetById(id) {
      await this.setUserById({ id });
      this.gHelperTitle = 'Got User By Id';
      this.gHelperData = this.vgUser;
    },

    async btnPutById(id) {
      // For testing pruposes... first getting the user
      await this.setUserById({ id });
      let user = this.vgUser;

      // Now modifying the user...
      user.Comment = 'this is modifiedddddddddddddddddd';
      this.updateUserById({ id, user });
      this.gHelperTitle = 'Put User By Id';
      this.gHelperData = this.vgUser;
    },

    async btnPost() {
      let uniqueId = 'zzz003';

      let oUser = {
        /*
        const { UserName,Email,Name,FirstName,Organisation,Telephone,IsApproved,Comment } = params ;  
        
        */

        UserName: `${uniqueId}`,
        Email: `${uniqueId}.test@gmail.com`,

        Name: `${uniqueId}`,
        FirstName: `${uniqueId}`,
        Organisation: 'Geoit',

        Telephone: '',
        IsApproved: true,
        Comment: '',
        PasswordQuestion: 'this is the password question...',

        /*
        ProviderUserKey: 96,
        IsLockedOut: false,
        LastLockoutDate: '2020-08-17T15:44:55.479056+02:00',
        CreationDate: '2020-08-17T15:44:55.479056+02:00',
        LastLoginDate: '0001-01-01T01:00:00+01:00',
        LastActivityDate: '2020-08-17T15:44:55.479056+02:00',
        LastPasswordChangedDate: '2020-08-17T15:44:55.479056+02:00',
        IsOnline: false,
        ProviderName: 'TerfSqlMembershipProvider',
        */
      };

      await this.createUser(oUser);
      this.gHelperTitle = 'Created User';
      this.gHelperData = this.vgUser;
    },

    async btnLogout() {
      await this.logoutUser({});
      this.gHelperTitle = 'LoggedIn User';
      this.gHelperData = this.vgUserLoggedIn;
    },

    async btnLogin() {
      this.UserName = 'johnny';
      this.Password = 'jdriesen';
      let data = {
        UserName: this.UserName,
        Password: this.Password,
      };

      await this.loginUser(data);
      this.gHelperTitle = 'LoggedIn User';
      this.gHelperData = this.vgUserLoggedIn;
    },
  }, // End of methods

  // ----- Start Hooks
  watch: {}, // End of watch

  async mounted() {},
};
</script>

<style scoped></style>
