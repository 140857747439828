<template>
  <div class="text-caption" v-html="vgReplaceCRLF(vgText)" />
</template>

<script>
export default {
  name: 'vgFicheItemText',

  props: {
    vgText: {
      type: String,
      required: false,
      default: () => '',
    },
  }, // End of props

  computed: {}, // end of Computed

  methods: {
    vgReplaceCRLF(text) {
      return text ? text.replaceAll('\r\n', '<br />') : '';
    },
  }, // End of methods
}; // End of Export
</script>

<style scoped></style>
