import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

import { i18n } from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";
import "@/plugins/vee-validate";

import InfiniteLoading from "vue-infinite-loading";
Vue.component("InfiniteLoading", InfiniteLoading);

// Loading and Using Components
import geoitComponents from "@/plugins/components";
Vue.use(geoitComponents);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

/*
import Vue from "vue";
import App from "./App.vue";
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

new Vue({
    vuetify,
    render: (h) => h(App)
}).$mount("#app");
*/
