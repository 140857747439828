<template>
  <div class="text-right text-subtitle-2 pt-0">{{ vgText }}</div>
</template>

<script>
export default {
  name: 'vgFicheItemTitle',

  props: {
    vgText: {
      type: String,
      required: false,
      default: () => '',
    },
  }, // End of props

  computed: {}, // end of Computed

  methods: {}, // End of Methods
}; // End of Export
</script>

<style scoped></style>
