// vuex vgRegion

import { gLog } from '@/services/GLogger';

import { getRegionById, postFiltered } from '@/services/http/Region';

const PRESET_NISCODE = null;
const REGION_NISCODE = '0';

export const state = {
  _niscode: PRESET_NISCODE,
  _presetNiscode: REGION_NISCODE,
  _vgRegion: null,
  _vgMunicipality: null,
  _vgRegionNiscodes: [],
  _vgNiscodesAndMunicipalities: [],
};

export const getters = {
  getVgRegion: state => state._vgRegion,
  getVgMunicipality: state => state._vgMunicipality, // this is the active Municipality... based on the niscode...
  // getVgRegionNiscodes: state => state._vgRegion.getArNiscodes(),

  getVgRegionNiscodes: state => state._vgRegionNiscodes,
  getVgNiscodesAndMunicipalities: state => state._vgNiscodesAndMunicipalities,

  getNiscode: state => state._niscode,
  getPresetNiscode: state => state._presetNiscode,
  getRegionNiscode: () => REGION_NISCODE,
};

export const mutations = {
  setRegion: (state, region) => {
    state._vgRegion = region;

    // Also full the _vgNiscodesAndMunicipalities
    // In fact, the sorting should be done at server-side...

    state._vgNiscodesAndMunicipalities = state._vgRegion.Municipalities.sort((a, b) => a.Name.localeCompare(b.Name)).map(el => ({
      niscode: el.Niscode,
      name: el.Name,
    }));
    // This will be show on top of the SearchList
    let allRegionMunicipalities = {
      niscode: REGION_NISCODE,
      name: state._vgRegion.Description,
    };
    state._vgNiscodesAndMunicipalities.unshift(allRegionMunicipalities);

    // last but not least... fill an array with all Region Niscodes
    state._vgRegionNiscodes = state._vgRegion.Municipalities.map(el => el.Niscode.toString());
  },

  setNiscode: (state, niscode) => {
    state._niscode = niscode;
    state._presetNiscode = niscode;

    // set the Municipality, based on the niscode
    state._vgMunicipality =
      parseInt(niscode) != parseInt(REGION_NISCODE)
        ? (state._vgMunicipality = state._vgRegion.Municipalities.find(m => parseInt(m.Niscode) == parseInt(niscode)))
        : null;
  },

  presetNiscode: (state, presetNiscode) => (state._presetNiscode = presetNiscode),
};

export const actions = {
  setNiscode: async ({ state, commit }, niscode) => commit('setNiscode', niscode),
  presetNiscode: async ({ state, commit }, preNiscode) => commit('presetNiscode', presetNiscode),

  setRegionById: async ({ commit }, payload) => {
    let { id = 0, params = {} } = payload;
    let region = await getRegionById(id, params);
    return commit('setRegion', region);
  },
};
