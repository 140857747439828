<template>
  <v-app-bar dense flat class="mb-0 pb-0 ml-0 mr-0 mt-0" height="25">
    <!-- Start Info Button -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon small color="grey lighten-1">mdi-information</v-icon>
        </v-btn>
      </template>
      <!--
      <span class="caption font-italic font-weight-medium">Last modified by Johnny Driesen on {{ dtModifiedOn }}</span
      -->
      <span class="caption font-italic font-weight-medium">details</span>
    </v-tooltip>
    <!-- End Info Button -->

    <span class="caption font-italic font-weight-medium">{{ gTitle }}</span>
    <v-spacer></v-spacer>

    <!-- Start Edit Button -->
    <div v-show="showEdit && gAllowEdit">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="mr-0" icon v-on="on" @click="btnEdit()">
            <v-icon small color="green lighten-1">edit</v-icon>
          </v-btn>
        </template>
        <span class="caption font-italic font-weight-medium">Wijzig</span>
      </v-tooltip>
    </div>

    <!-- End Edit Button -->
  </v-app-bar>
</template>

<script>
export default {
  name: 'vStickerToolbar',

  props: {
    gTitle: {
      type: String,
      required: false,
      default: () => 'Unknown title in gStickerToolbar',
    },

    gAllowEdit: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  computed: {
    showEdit() {
      // return this.dsnMode === "tabs";
      return true;
    },

    /*
    dtModified() {
      let modifiedby = "johnny driesen";
      let modifiedon = this.dsnDatetimeUnix2ModifiedOn(this.dtaItem.modifiedon);

      return this.$t("app.sticker.toolbar.modifiedby.tooltip", {
        modifiedby,
        modifiedon
      });
    }
    */
  }, // end of Computed

  directives: {},

  components: {},

  data: () => ({}),

  methods: {
    btnEdit() {
      // inform the viewList we're going to EDIT Mode
      // console.log("dtaItem in StickerToolbar", this.dtaItem);
      // inform the viewList we're going to EDIT Mode
      // this.$dsnShout("changeMode", "edit");
      // Limit the list to only this record ...
      // this.$dsnShout("selectorLimitList", this.dtaItem);
    },

    // Hook Handlers
  },

  // ----- Hooks
  created() {},

  mounted() {
    // console.log("dtaItem", this.dtaItem);
  },

  beforeDestroy() {},
};
</script>

<style></style>
