<template>
  <v-row>
    <v-col cols="1" class="d-flex justify-start">
      <v-card flat tile>
        <v-img :src="logo_gem" :width="theWidth" contain class="mt-2" />
      </v-card>
    </v-col>
    <v-col cols="9" class="d-flex justify-center">
      <v-card flat tile>
        <h2 class="secondary--text">Geo-IT Service Tester</h2>
        <h3>Testing Services Generated by the Swagger Parser Tool</h3>
      </v-card>
    </v-col>
    <v-col cols="2" class="d-flex justify-end">
      <!--
      <v-card flat tile>
        <v-img :src="srcLogoMidwest" :width="widthLogo" contain />
      </v-card>
      -->
      <v-card flat tile>
        <v-img :src="logo_gem" :width="theWidth" contain class="mt-2" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Services
import { gLog } from '@/services/GLogger';

// mixins
import mixinApp from '@/mixins/App';

//
import { mapGetters } from 'vuex';

export default {
  name: 'VgHeader',

  mixins: [mixinApp], // End of mixins

  computed: {
    theWidth() {
      let retValue = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          // case 'sm':

          retValue = 70;
          break;
        default:
          retValue = 100;
          break;
      }
      return retValue;
    },

    logo_gem() {
      return require(`@/assets/logos/logo_geo-it.png`);

      // return require(`@/assets/logos/${this.logoInfo.logo}`);
      // return require(`@/assets/logos/antwerpen.png`);
      // return require(`@/assets/logos/ardooie.png`);
      // return require(`@/assets/logos/geraardsbergen.png`);
      // return require(`@/assets/logos/hooglede.png`);
      // return require(`@/assets/logos/ingelmunster.png`);
      // return require(`@/assets/logos/izegem.svg`);
      // return require(`@/assets/logos/leuven.png`);
      // return require(`@/assets/logos/ledegem.png`);
      // return require(`@/assets/logos/lichtervelde.svg`);
      // return require(`@/assets/logos/londerzeel.png`);
      // return require(`@/assets/logos/meulebeke.png`);
      // return require(`@/assets/logos/moorslede.png`);
      // return require(`@/assets/logos/oostrozebeke.png`);
      // return require(`@/assets/logos/pittem.png`);
      // return require(`@/assets/logos/roeselare.png`);
      // return require(`@/assets/logos/ruiselede.png`);
      // return require(`@/assets/logos/staden.png`);
      // return require(`@/assets/logos/tielt.png`);
      // return require(`@/assets/logos/wielsbeke.svg`);
      // return require(`@/assets/logos/wingene.png`);
    },

    logo_midwest() {
      // return require(`@/assets/logos/midwest.png`);
      // return require(`@/assets/logos/antwerpen.png`);
      return this.vgRegion.Logo;
    },
  }, // End of computed

  components: {}, // End of components

  data: () => ({
    logo: require('@/assets/logos/midwest.png'), // 200

    title: {
      main: 'Versteende getuigenissen',
      sub: 'Vind (erfgoed)graven in Midwest',
    },
  }), // End of data

  methods: {}, // End of methods

  watch: {}, // End of watch
};
</script>
