// vuex hoLayout

import { gLog } from '@/services/GLogger';

const regionFlanders = [
  [2.420136371484375, 50.81147971093219],
  [5.749766803431243, 51.50030991180077],
];
const regionBelgium = [
  [2.4714345893252982, 49.45498940608738],
  [6.073989507846562, 51.50753269334288],
];

// const sw = new mapboxgl.LngLat(32.958984, -5.353521);
// const ne = new mapboxgl.LngLat(43.50585, 5.615985);
const regionKenia = [
  [32.958984, -5.353521],
  [43.50585, 5.615985],
];

export const state = {
  _regionFlanders: regionFlanders,
  _regionBelgium: regionBelgium,
  _regionKenia: regionKenia,
};

export const getters = {
  getRegionFlanders: state => state._regionFlanders,
  getRegionBelgium: state => state._regionBelgium,
  getRegionKenia: state => state._regionKenia,
};

export const mutations = {};

export const actions = {};
